<template>
  <div class="block w-1/2">
    <h6
      @click="showTemplate = !showTemplate"
      class="text-md py-4 mt-3 mb-2 font-bold uppercase cursor-pointer"
    >
      {{ template.name }}
      <i
        class="ml-3 fa-sharp fa-solid"
        :class="showTemplate ? 'fa-chevron-up' : 'fa-chevron-down'"
      ></i>
    </h6>
    <div
      class="transition-all overflow-hidden"
      :class="showTemplate ? 'max-h-96' : 'max-h-0'"
    >
      <form @submit="updateSmsTemplate" class="px-6">
        <div class="flex flex-col mb-6">
          <label for="sms_originator">
            <b>Sms originator / sender</b>
          </label>
        </div>
        <div class="flex flex-col mb-6">
          <label for="sms_body">
            <b>Sms text / body</b>
          </label>
          <textarea
            v-model="body"
            rows="6"
            name="sms_body"
            id="sms_body"
          ></textarea>
        </div>
        <button
          class="
            bg-emerald-500
            active:bg-emerald-600
            uppercase
            text-white
            font-bold
            hover:shadow-md
            shadow
            text-xs
            px-4
            py-2
            rounded
            outline-none
            focus:outline-none
            sm:mr-2
            mb-1
            ease-linear
            transition-all
            duration-150
          "
          type="submit"
        >
          Save
        </button>
      </form>
    </div>
  </div>
  <teleport to="#modal">
    <SuccesAlert :show="showAlert" @resetShow="showAlert = false" />
    <Modal v-if="openModal" :title="modalTitle" :text="modalText" @closeModal="openModal = false" />
  </teleport>
</template>


<script>
import SuccesAlert from "@/components/Elements/SuccesAlert.vue";
import Modal from "@/components/Elements/Modal.vue";

import { updateSmsTemplate } from '@/graphql/sms/mutations';

export default {
  props: {
    template: {
      require: true,
    }
  },
  data() {
    return {
      showTemplate: false,
      originator: this.template.originator,
      body: this.template.body,
      showAlert: false,
      openModal: false,
      modalTitle: '',
      modalText: ''
    };
  },
  components: {
    SuccesAlert,
    Modal
  },
  methods: {
    async updateSmsTemplate(e) {
      e.preventDefault();
      try {
        const res = await this.$apollo.mutate({
          mutation: updateSmsTemplate,
          variables: {
            input: {
              name: this.template.name,
              originator: this.originator,
              body: this.body
            }
          },
        });

        if (res?.data?.updateSmsTemplate.status === 200) {
          this.showAlert = true;
          this.showTemplate = false;
        } else {
          this.modalTitle = "Er ging iets mis.";
          this.modalText = res?.data?.updateSmsTemplate.statusMsg;
          this.openModal = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>