import gql from 'graphql-tag';
// @TODO: only the things needed
export const getPaginatedReservations = gql`
  query PaginatedReservationsQuerySchema($weekendID: Int!, $searchWord: String, $currPage: Int!, $itemsPP: Int!) {
    PaginatedReservations (weekendID: $weekendID, searchWord: $searchWord, currPage: $currPage, itemsPP: $itemsPP) {
      reservations {
        id
        remarks
        internal_remarks
        reservationnumber
        createdDateTime
        payment_status
        deposit_feeID
        normal_feeID
        genderOfTeam
        status
        numberofguests
        deposit_fee {
          amount
        }
        remnant_fee {
          amount
        }
        reservationPayments {
          amount
          status
          type
        }
        team {
          name
          guid
          main_booker_user {
            id
            name
            surename
            phone
          }
        }
      }
      count
    }
  }
`;
// @TODO: only the things needed
export const getReservation = gql`
  query Reservation($reservationnumber: String!) {
    reservation(reservationnumber: $reservationnumber) {
      weekendID
      memberID
      teamID
      tent_optionID
      payment_DateTime
      depositDeadlineDateTime
      genderOfTeam
      numberofguests
      status
      voucher {
        guid
        initialAmount
        amount
      }
      reservationPayments {
        amount
        status
        reservationID
        memberID
        nog
        type
        reservationnumber
        guid
        splitpayment
        voucherId
        voucherGuid
        molliePayment {
          ref
          status
        }
      }
      team {
        id
        name
        changedName
        members {
          id
          guid
          name
          surename
          email
          main_booker
          phone
          activated_at
          password_updatedAt
        }
        main_booker_user {
          id
          name
          surename
          phone
        }
      }
      tent {
        id
        tent_optionID
        quantity
        status
        tent_option {
          name
          capacity_text
          price
        }
      }
    }
  }
`;

export const getWaitinglist = gql`
  query Waitinglist($orderBy: String!, $weekendID: Int!, $searchWord: String, $currPage: Int!, $itemsPP: Int!) {
    waitinglist(orderBy: $orderBy, weekendID: $weekendID, searchWord: $searchWord, currPage: $currPage, itemsPP: $itemsPP) {
      reservations {
        id
        remarks
        internal_remarks
        reservationnumber
        createdDateTime
        payment_status
        deposit_feeID
        normal_feeID
        genderOfTeam
        status
        numberofguests
        deposit_fee {
          amount
        }
        remnant_fee {
          amount
        }
        reservationPayments {
          amount
          status
        }
        team {
          name
          guid
          main_booker_user {
            id
            name
            surename
            phone
          }
        }
      }
      count
    }
  }
`;

export const getSearchPaginatedReservations = gql`
  query SearchPaginatedReservationsQuerySchema($weekendID: Int!, $searchWord: String) {
    SearchPaginatedReservations (weekendID: $weekendID, searchWord: $searchWord) {
      reservations {
        id
        remarks
        internal_remarks
        reservationnumber
        createdDateTime
        payment_status
        deposit_feeID
        normal_feeID
        genderOfTeam
        status
        numberofguests
        deposit_fee {
          amount
        }
        remnant_fee {
          amount
        }
        reservationPayments {
          amount
          status
          type
        }
        team {
          name
          guid
          main_booker_user {
            id
            name
            surename
            phone
          }
        }
      }
      count
    }
  }
`;