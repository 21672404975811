const getters = {
  currWeekendId(state) {
    return state.currWeekendId;
  },
  isLoggedIn(state) {
    return state.loggedIn;
  },
  role(state) {
    return state.role;
  }
};

export default getters;
