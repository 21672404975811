import gql from 'graphql-tag';

export const addNumberOfGuests = gql`
  mutation addNumberOfGuests($input: AddNumberOfGuests!) {
    AddNumberOfGuestsReservation(input: $input) {
      status
      statusMsg
    }
  }
`;

export const createReservation = gql`
  mutation Reservationcreate($input: CreateReservationInput!) {
    createReservation(input: $input) {
      status
      statusMsg
    }
  }
`;

export const AdminCreateReservation = gql`
  mutation AdnminCeateReservation($input: CreateReservationInput!) {
    adminCreateReservation(input: $input) {
      status
      statusMsg
    }
  }
`;

export const ChangeReservationStatus = gql`
  mutation ChangeReservationStatus($reservationnumber: String!, $status: Int!) {
    ChangeReservationStatus(reservationnumber: $reservationnumber, status: $status) {
      status
      statusMsg
    }
  }
`;

export const ChangeComment = gql`
  mutation ChangeComment($input: ChangeCommentInput!) {
    ChangeComment(input: $input) {
      status
      statusMsg
    }
  }
`;

export const ChangeReservationNog = gql`
  mutation ChangeReservationNog($reservationnumber: String!, $nog: Int!) {
    ChangeReservationNog(reservationnumber: $reservationnumber, nog: $nog) {
      status
      statusMsg
    }
  }
`;
