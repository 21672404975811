<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded
    "
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative flex w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            Events
          </h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Weekend ID
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              locationName
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Start date
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Eind tijd
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Delete
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="event in events" :key="`event-${event.id}`">
            <th
              class="
                border-t-0
                pr-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
                text-left
              "
            >
              <span
                class="ml-3 font-bold"
                :class="[
                  color === 'light' ? 'text-blueGray-600' : 'text-white',
                ]"
              >
                {{ event?.id }}
              </span>
            </th>
            <th
              class="
                border-t-0
                pr-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
                text-left
              "
            >
              <span
                class="ml-3 font-bold"
                :class="[
                  color === 'light' ? 'text-blueGray-600' : 'text-white',
                ]"
              >
                {{ event?.locationName }}
              </span>
            </th>
            <th
              class="
                border-t-0
                pr-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
                text-left
              "
            >
              <span
                class="ml-3 font-bold"
                :class="[
                  color === 'light' ? 'text-blueGray-600' : 'text-white',
                ]"
              >
                {{ $date(event.startsAt).format("DD-MM-YYYY HH:mm") }}
              </span>
            </th>
            <th
              class="
                border-t-0
                pr-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
                text-left
              "
            >
              <span
                class="ml-3 font-bold"
                :class="[
                  color === 'light' ? 'text-blueGray-600' : 'text-white',
                ]"
              >
                {{ $date(event.endsAt).format("DD-MM-YYYY HH:mm") }}
              </span>
            </th>

            <th
              class="
                border-t-0
                pr-6
                align-middle
                border-l-0 border-r-0
                text-xs
                whitespace-nowrap
                p-4
                text-left
              "
            >
              <span
                class="ml-3 font-bold cursor-pointer"
                :class="[
                  color === 'light' ? 'text-blueGray-600' : 'text-white',
                ]"
                @click="removeEvent(event?.id)"
              >
                <i
                  class="fas fa-trash-can"
                ></i>
              </span>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <teleport to="#modal">
      <SuccesAlert :show="showAlert" @resetShow="showAlert = false" />
      <Modal
        v-if="openModal"
        :title="modalTitle"
        :text="modalText"
        @closeModal="openModal = false"
      />
    </teleport>
  </div>
</template>

<script>
import { RemoveEvent } from "@/graphql/events/mutations.js";
import SuccesAlert from "@/components/Elements/SuccesAlert.vue";

export default {
  props: {
    events: {
      default: null,
    },
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    SuccesAlert,
  },
  data() {
    return {
      showAlert: false,
    };
  },
  methods: {
    async removeEvent(id) {
      const eventId = parseInt(id);

      try {
        const res = await this.$apollo.mutate({
          mutation: RemoveEvent,
          variables: {
            eventId,
          },
        });

        if (res?.data?.RemoveEvent.status === 200) {
          this.$emit("refetch");
          this.showAlert = true;
        } else {
          this.modalTitle = "Er ging iets mis.";
          this.modalText = res?.data?.ChangeReservationNog.statusMsg;
          this.openModal = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
