<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      shadow-lg
      rounded
      bg-white
      mb-6
      py-3
    "
  >
    <div class="rounded-t mb-0 px-4 border-0">
      <div class="flex flex-wrap items-center">
        <div
          class="
            relative
            w-full
            flex
            items-center
            px-4
            max-w-full
            flex-grow flex-1
          "
        >
          <h3 class="font-semibold text-lg">SMS Templates</h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto px-12">
      <SmsItem
        v-for="template in AllSmsTemplates"
        :key="`tem-${template.id}`"
        :template="template"
      />
    </div>
  </div>

  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      shadow-lg
      rounded
      bg-white
      py-3
    "
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div
          class="
            relative
            w-full
            flex
            items-center
            px-4
            max-w-full
            flex-grow flex-1
          "
        >
          <h3 class="font-semibold text-lg">Send sms</h3>
        </div>
      </div>
    </div>
    <div class="w-1/2 block overflow-x-auto px-12">
      <form ref="senSmsForm" class="px-6">
        <div class="flex flex-col mb-6">
          <label for="receivers">
            <b>Ontvangers:</b>
          </label>
          <div class="px-3">
            <span class="flex items-center">
              Iedereen:
              <input
                class="ml-2"
                v-model="receivers"
                value="everyone"
                type="radio"
              />
            </span>
            <span class="flex items-center">
              Hoofdboekers:
              <input
                class="ml-2"
                v-model="receivers"
                value="mainbookers"
                type="radio"
              />
            </span>
            <span class="flex items-center">
              Iedereen onvolledig betaald:
              <input
                class="ml-2"
                v-model="receivers"
                value="everyoneUnpaid"
                type="radio"
              />
            </span>
            <span class="flex items-center">
              Hoofdboekers onvolledig betaald:
              <input
                class="ml-2"
                v-model="receivers"
                value="mainbookersUnpaid"
                type="radio"
              />
            </span>
          </div>
        </div>
        <div class="flex flex-col mb-6">
          <label for="sms_originator">
            <b>Sms originator / verzender</b>
          </label>
        </div>
        <div class="flex flex-col mb-6">
          <label for="sms_originator">
            <b>SMS bericht</b>
          </label>
          <textarea
            v-model="msg"
            name="sms_originator"
            id="sms_originator"
            rows="6"
          ></textarea>
        </div>
        <button
          @click="openWarrningModal"
          class="
            bg-emerald-500
            active:bg-emerald-600
            uppercase
            text-white
            font-bold
            hover:shadow-md
            shadow
            text-xs
            px-4
            py-2
            rounded
            outline-none
            focus:outline-none
            sm:mr-2
            mb-1
            ease-linear
            transition-all
            duration-150
          "
        >
          Save
        </button>
      </form>
    </div>
  </div>
  <teleport to="#modal">
    <SuccesAlert :show="showAlert" @resetShow="showAlert = false" />
    <Modal
      v-if="openModal"
      :title="modalTitle"
      :text="modalText"
      @closeModal="openModal = false"
    >
      <template #button>
        <button
        v-if="showSendButton"
          @click="sendSms"
          class="
            bg-emerald-500
            active:bg-emerald-600
            uppercase
            text-white
            font-bold
            hover:shadow-md
            shadow
            text-xs
            px-4
            py-2
            rounded
            outline-none
            focus:outline-none
            sm:mr-2
            mb-1
            ease-linear
            transition-all
            duration-150
          "
        >
          SEND
        </button>
      </template>
    </Modal>
  </teleport>
</template>

<script>
import SmsItem from "@/components/Elements/SmsItem.vue";
import { getSmsTemplates, sendSms } from "@/graphql/sms/query";

import SuccesAlert from "@/components/Elements/SuccesAlert.vue";
import Modal from "@/components/Elements/Modal.vue";

import { getEvents } from "@/graphql/events/query.js";

export default {
  data() {
    return {
      sending: false,
      receivers: null,
      originator: null,
      msg: "",
      openModal: false,
      modalTitle: "",
      modalText: "",
      showAlert: false,
      showSendButton: true,
    };
  },
  components: {
    SmsItem,
    SuccesAlert,
    Modal,
  },
  apollo: {
    AllSmsTemplates() {
      return {
        query: getSmsTemplates,
      };
    },
    events() {
      return {
        query: getEvents,
      };
    },
  },
  computed: {
    currEvents() {
      return this.events.find(
        (event) => event.id == this.$store.getters.currWeekendId
      );
    },
  },
  
  methods: {
    async sendSms() {
      if (!this.sending) {
        this.sending = true;

        try {
          const res = await this.$apollo.query({
            query: sendSms,
            variables: {
              weekendID: this.$store.getters.currWeekendId,
              receivers: this.receivers,
              body: this.msg
            },
          });

          if (res?.data?.SendSms.status === 200) {
            this.showAlert = true;
            this.openModal = false;
            this.sending = false;
          } else {
            this.showSendButton = false;
            this.modalTitle = "Er ging iets mis.";
            this.modalText = res?.data?.SendSms.statusMsg;
            this.openModal = false;
            setTimeout(this.openModal = true, 2000);
            this.sending = false;
          }
        } catch (e) {
          console.error(e);
        }

      }
    },
    openWarrningModal(e) {
      this.showSendButton = true;
      e.preventDefault();

      if (this.receivers != null && this.msg != "") {
        this.modalTitle = "Weet je het zeker?";
        this.modalText = `
          <h4 class="text-2xl mb-4">je staat op het punt:</h4>
          <b>Dit bericht:</b>
          <p>${this.msg}</p>
          <b>Te sturen naar:</b>
          <p>
            <b>${new Date(this.currEvents.startsAt).getFullYear()}</b> 
            - 
            ${
              this.receivers == "everyone"
                ? "Iedereen"
                : this.receivers == "mainbookers"
                ? "Alle hoofdboekers"
                : this.receivers == "everyoneUnpaid"
                ? "Iedereen uit teams die nog niet volledig hebben betaald"
                : this.receivers == "mainbookersUnpaid"
                ? "Hoofdbokers van teams die nog niet volledig hebben betaald"
                : "err"
            }</p>
        `;
        this.openModal = true;
      }
    },
  },
};
</script>