<template>
  <tr v-if="reservation" class="cursor-pointer">
    <th
      class="
        border-t-0
        pr-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
        text-left
      "
    >
      <span
        class="ml-3 font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        {{ $date(reservation?.createdDateTime).format("DD-MM-YYYY HH:mm") }}
      </span>
    </th>
    <th
      class="
        border-t-0
        pr-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
        text-left
      "
    >
      <span
        class="ml-3 font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        {{ reservation?.team?.name }}
      </span>
    </th>
    <th
      class="
        border-t-0
        pr-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
        text-left
      "
    >
      <span
        class="ml-3 font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        &euro;
        {{
          formattedCurrency(
            reservation?.numberofguests * reservation?.remnant_fee?.amount -
              payedAmount
          )
        }}
      </span>
    </th>

    <th
      class="
        border-t-0
        pr-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
        text-left
      "
    >
      <span
        class="ml-3 font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        {{
          remanantPayedWithVoucherAmount == 0 && remanantPayedNoVoucher == 0
            ? `&euro; ${calcAndReturnDepositVoucherNoRemnant(
                depositPayedWithVoucherAmount
              )}`
            : "nvt"
        }}
      </span>
    </th>

    <th
      class="
        border-t-0
        pr-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
        text-left
      "
    >
      <span
        class="ml-3 font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        {{
          remanantPayedWithVoucherAmount == 0 && remanantPayedNoVoucher == 0
            ? `&euro; ${calcAndReturnDepositNoRemnant(depositPayedNoVoucher)}`
            : "nvt"
        }}
      </span>
    </th>

    <th
      class="
        border-t-0
        pr-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
        text-left
      "
    >
      <span
        class="ml-3 font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        &euro; {{ formattedCurrency(depositPayedWithVoucherAmount) }}
      </span>
    </th>

    <th
      class="
        border-t-0
        pr-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
        text-left
      "
    >
      <span
        class="ml-3 font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        &euro; {{ formattedCurrency(depositPayedNoVoucher) }}
      </span>
    </th>
    <th
      class="
        border-t-0
        pr-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
        text-left
      "
    >
      <span
        class="ml-3 font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        &euro; {{ formattedCurrency(remanantPayedWithVoucherAmount) }}
      </span>
    </th>
    <th
      class="
        border-t-0
        pr-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
        text-left
      "
    >
      <span
        class="ml-3 font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        &euro; {{ formattedCurrency(remanantPayedNoVoucher) }}
      </span>
    </th>
    <th
      class="
        border-t-0
        pr-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
        text-left
      "
    >
      <span
        class="ml-3 font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        &euro; {{ formattedCurrency(payedAmountTent) }}
      </span>
    </th>
  </tr>
</template>
<script setup>
import formattedCurrency from "@/formattedCurrency.js";
</script>
<script>
export default {
  components: {},
  data() {
    return {
      payedAmount: 0,
      payedAmountTent: 0,
      depositPayedNoVoucher: 0,
      remanantPayedNoVoucher: 0,
      depositPayedWithVoucherAmount: 0,
      remanantPayedWithVoucherAmount: 0,
      emitedDepositVoucherNoRemnant: false,
      emitedDepositNoRemnant: false,
    };
  },
  props: {
    reservation: {
      default: [],
    },
    totalRes: {
      default: 0,
      type: Number,
    },
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  mounted() {
    const reservationPayments = this.reservation?.reservationPayments;
    for (let index = 0; index < reservationPayments?.length; index++) {
      if (reservationPayments[index].status === 1) {
        if (reservationPayments[index].type !== "tent") {
          this.payedAmount += reservationPayments[index].amount;

          if (reservationPayments[index].voucherGuid) {
            if (reservationPayments[index].type == "deposit") {
              this.depositPayedWithVoucherAmount +=
                reservationPayments[index].amount;
            }

            if (reservationPayments[index].type == "normal") {
              this.remanantPayedWithVoucherAmount +=
                reservationPayments[index].amount;
            }
          } else {
            if (reservationPayments[index].type == "deposit") {
              this.depositPayedNoVoucher += reservationPayments[index].amount;
            }

            if (reservationPayments[index].type == "normal") {
              this.remanantPayedNoVoucher += reservationPayments[index].amount;
            }
          }
        } else {
          this.payedAmountTent += reservationPayments[index].amount;
        }
      }
    }
  },
  methods: {
    calcAndReturnDepositVoucherNoRemnant(val) {
      if (val > 0 && !this.emitedDepositVoucherNoRemnant) {
        this.$emit("calc-DepositVoucherNoRemnant", val);
        this.emitedDepositVoucherNoRemnant = true;
      }
      return formattedCurrency(val);
    },
    calcAndReturnDepositNoRemnant(val) {
      if (val > 0 && !this.emitedDepositNoRemnant) {
        this.$emit("calc-DepositNoRemnant", val);
        this.emitedDepositNoRemnant = true;
      }
      return formattedCurrency(val);
    },
  },
};
</script>


<style scoped>
thead,
tbody tr {
  table-layout: fixed; /* even columns width , fix width of table too*/
}
</style>
