<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      bg-white
      w-full
      mb-6
      shadow-lg
      rounded
    "
  >
    <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h6 class="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
            {{ subTitle }}
          </h6>
          <h2 class="text-blueGray-700 text-xl font-semibold">{{ title }}</h2>
        </div>
      </div>
    </div>
    <div class="p-4 flex-auto">
      <div :id="`canvasContainer-${chartId}`" class="relative h-350-px">
        <canvas :id="`bar-chart-${chartId}`"></canvas>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js";
import moment from "moment";

export default {
  data() {
    return {
      labels: {
        weekly: [],
        Monthly: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
      },
    };
  },
  props: {
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    chartId: {
      type: String,
    },
    loading: {
      default: true,
    },
    first: {
      default: false,
    },
    firstLable: {
      type: String,
    },
    second: {
      default: false,
    },
    secondLable: {
      type: String,
    },
    third: {
      default: false,
    },
    thirdLable: {
      type: String,
    },
    labelType: {
      type: String,
      default: "Monthly",
    },
    weekNrYearToShow: {
      default: "2021",
    },
    weekNrToShow: {
      default: '22',
    },
  },
  watch: {
    loading: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.renderChart();
      }
    },
    "$store.getters.currWeekendId": function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.renderChart();
      }
    },
    title: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.renderChart();
      }
    },
  },
  methods: {
    renderChart() {
      // if i make a var of moment(this.weekNrYearToShow).startOf("year").add(this.weekNrToShow, "weeks").startOf("isoWeek")
      // the days in the labels ar not working corect.
      this.$nextTick(function () {
        this.labels.weekly = [
          `MA ${moment(this.weekNrYearToShow)
            .startOf("year")
            .add(this.weekNrToShow, "weeks")
            .startOf("isoWeek")
            .format("DD-MM")}`,
          `Di ${moment(this.weekNrYearToShow)
            .startOf("year")
            .add(this.weekNrToShow, "weeks")
            .startOf("isoWeek")
            .add(1, "days")
            .format("DD-MM")}`,
          `Wo ${moment(this.weekNrYearToShow)
            .startOf("year")
            .add(this.weekNrToShow, "weeks")
            .startOf("isoWeek")
            .add(2, "days")
            .format("DD-MM")}`,
          `DO ${moment(this.weekNrYearToShow)
            .startOf("year")
            .add(this.weekNrToShow, "weeks")
            .startOf("isoWeek")
            .add(3, "days")
            .format("DD-MM")}`,
          `VR ${moment(this.weekNrYearToShow)
            .startOf("year")
            .add(this.weekNrToShow, "weeks")
            .startOf("isoWeek")
            .add(4, "days")
            .format("DD-MM")}`,
          `ZA ${moment(this.weekNrYearToShow)
            .startOf("year")
            .add(this.weekNrToShow, "weeks")
            .startOf("isoWeek")
            .add(5, "days")
            .format("DD-MM")}`,
          `ZO ${moment(this.weekNrYearToShow)
            .startOf("year")
            .add(this.weekNrToShow, "weeks")
            .startOf("isoWeek")
            .add(6, "days")
            .format("DD-MM")}`,
        ];
        let config = {
          type: "bar",
          data: {
            labels: this.labels[this.labelType],
            datasets: [
              {
                label: this.firstLable,
                backgroundColor: "#ed64a6",
                borderColor: "#ed64a6",
                data: this.first,
                fill: false,
                barThickness: 8,
              },
              {
                label: this.secondLable,
                fill: false,
                backgroundColor: "#4c51bf",
                borderColor: "#4c51bf",
                data: this.second,
                barThickness: 8,
              },
              {
                label: this.thirdLable,
                fill: false,
                backgroundColor: "#059669",
                borderColor: "#059669",
                data: this.third,
                barThickness: 8,
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
            responsive: true,
            title: {
              display: false,
              text: "Orders Chart",
            },
            tooltips: {
              mode: "index",
              intersect: false,
            },
            hover: {
              mode: "nearest",
              intersect: true,
            },
            legend: {
              labels: {
                fontColor: "rgba(0,0,0,.4)",
              },
              align: "end",
              position: "bottom",
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: true,
                  },
                  gridLines: {
                    borderDash: [2],
                    borderDashOffset: [2],
                    color: "rgba(33, 37, 41, 0.3)",
                    zeroLineColor: "rgba(33, 37, 41, 0.3)",
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  scaleLabel: {
                    display: false,
                    labelString: "Value",
                  },
                  gridLines: {
                    borderDash: [2],
                    drawBorder: false,
                    borderDashOffset: [2],
                    color: "rgba(33, 37, 41, 0.2)",
                    zeroLineColor: "rgba(33, 37, 41, 0.15)",
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                  },
                },
              ],
            },
          },
        };

        document.getElementById(`bar-chart-${this.chartId}`).remove();

        const container = document.getElementById(
          `canvasContainer-${this.chartId}`
        );

        container.innerHTML = `<canvas id="bar-chart-${this.chartId}"></canvas>`;

        let ctx = document
          .getElementById(`bar-chart-${this.chartId}`)
          .getContext("2d");
        window[`myBar${this.chartId}`] = new Chart(ctx, config);
        window[`myBar${this.chartId}`].destroy();
        window[`myBar${this.chartId}`] = new Chart(ctx, config);
      });
    },
  },
};
</script>
