<template>
  <!-- Header -->
  <div class="relative bg-emerald-600 md:pt-32 pb-32 pt-12">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4">
            <card-stats
              statSubtitle="Aantal reserveringen"
              :statTitle="total"
              :statArrow="
                (resAmountLastMonth / resAmountPrevMonth) * 100 < 100
                  ? 'down'
                  : 'up'
              "
              :statPercent="
                resAmountPrevMonth > 0
                  ? ((resAmountLastMonth / resAmountPrevMonth) * 100).toFixed(2)
                  : (resAmountLastMonth * 100).toFixed(2)
              "
              :statPercentColor="
                (resAmountLastMonth / resAmountPrevMonth) * 100 < 100
                  ? 'text-red-500'
                  : 'text-emerald-500'
              "
              statDescripiron="Since last month"
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4">
            <card-stats
              statSubtitle="Aantal personen"
              :statTitle="totalNumberOfGuests"
              :statArrow="
                (totalNumberOfGuestsLastMonth / totalNumberOfGuestsPrevMonth) *
                  100 <
                100
                  ? 'down'
                  : 'up'
              "
              :statPercent="
                totalNumberOfGuestsPrevMonth > 0
                  ? (totalNumberOfGuestsLastMonth /
                      totalNumberOfGuestsPrevMonth) *
                    100
                  : (totalNumberOfGuestsLastMonth / 100) * 100
              "
              :statPercentColor="
                (totalNumberOfGuestsLastMonth / totalNumberOfGuestsPrevMonth) *
                  100 <
                100
                  ? 'text-red-500'
                  : 'text-emerald-500'
              "
              statDescripiron="Since last month"
              statIconName="fas fa-chart-pie"
              statIconColor="bg-orange-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4">
            <card-stats
              statSubtitle="Aanbetalingen betaald"
              :statTitle="`${totalDepositPayedPercentage?.toFixed(
                2
              )}% - (${Math.ceil(
                (totalNumberOfGuests / 100) * totalDepositPayedPercentage
              )} persoon)`"
              statArrow="down"
              :isPercentage="false"
              statIconName="fas fa-users"
              statIconColor="bg-pink-500"
              :statPercent="`${teamDepositPaymentsCount} ${
                teamDepositPaymentsCount !== 1 ? 'teams' : 'team'
              }`"
              :statDescripiron="`Made a deposit. Teams: <span class='text-emerald-500'>${depositPaymentsNogCountWomen} vrouwen</span> , <span class='text-emerald-500'>${depositPaymentsNogCountMan} mannen</span> en <span class='text-emerald-500'>${depositPaymentsNogCountMix} gemixed</span>`"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4">
            <card-stats
              statSubtitle="Rest. betalingen betaald"
              :statTitle="`${totalRemnantPayedPercentage?.toFixed(
                2
              )}% - (${Math.ceil(
                (totalNumberOfGuests / 100) * totalRemnantPayedPercentage
              )} persoon)`"
              statArrow="up"
              :isPercentage="false"
              statPercentColor="text-emerald-500"
              statIconName="fas fa-percent"
              statIconColor="bg-emerald-500"
              :statPercent="`${teamRemnantPaymentsCount} ${
                teamRemnantPaymentsCount !== 1 ? 'teams' : 'team'
              }`"
              :statDescripiron="`Made a remnant payment. Teams: <span class='text-emerald-500'>${remnantPaymentsNogCountWomen} vrouwen</span> , <span class='text-emerald-500'>${remnantPaymentsNogCountMan} mannen</span> en <span class='text-emerald-500'>${remnantPaymentsNogCountMix} gemixed</span>`"
            />
          </div>
        </div>
        <div class="flex justify-end px-4 mt-4">
          <eventSelector />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import CardStats from "@/components/Cards/CardStats.vue";
import eventSelector from "@/components/Elements/EventSelect.vue";

import { getEventStats } from "@/graphql/events/query.js";

export default {
  components: {
    CardStats,
    eventSelector,
  },
  data() {
    return {
      resAmountPrevMonth: null,
      resAmountLastMonth: null, // one month from now
      totalNumberOfGuests: null,
      totalNumberOfGuestsPrevMonth: null,
      totalNumberOfGuestsLastMonth: null,
      totalDepositPayedPercentage: null,
      totalRemnantPayedPercentage: null,
      teamDepositPaymentsCount: 0,
      amountOfPeoplePayedDeposit: 0,
      depositPaymentsNogCountMan: 0,
      depositPaymentsNogCountWomen: 0,
      depositPaymentsNogCountMix: 0,
      remnantPaymentsNogCountMan: 0,
      remnantPaymentsNogCountWomen: 0,
      remnantPaymentsNogCountMix: 0,
      teamRemnantPaymentsCount: 0,
      total: 0,
    };
  },
  apollo: {
    eventStats() {
      return {
        query: getEventStats,
        variables: () => {
          return {
            weekendID: parseInt(this.$store.getters.currWeekendId),
          };
        },
        skip: () =>
          !this.$store.getters.currWeekendId,
        result({ data }) {
          const reservations = data?.eventStats?.reservations;
          this.total = data?.eventStats?.count;

          this.resAmountPrevMonth = reservations.filter((o) =>
            moment(o.createdDateTime, "YYYY-MM-DD").isBetween(
              moment().subtract(2, "months"),
              moment().subtract(1, "months"),
              undefined,
              "[]"
            )
          ).length;

          this.resAmountLastMonth = reservations.filter((o) =>
            moment(o.createdDateTime, "YYYY-MM-DD").isBetween(
              moment().subtract(1, "months"),
              moment().subtract(0, "months"),
              undefined,
              "[]"
            )
          ).length;

          this.totalNumberOfGuests = reservations
            .filter(({ status }) => status !== 0)
            .reduce((a, b) => a + b.numberofguests, 0);

          this.totalNumberOfGuestsPrevMonth = reservations
            .filter((o) =>
              moment(o.createdDateTime, "YYYY-MM-DD").isBetween(
                moment().subtract(2, "months"),
                moment().subtract(1, "months"),
                undefined,
                "[]"
              )
            )
            .filter(({ status }) => status !== 0)
            .reduce((a, b) => a + b.numberofguests, 0);

          this.totalNumberOfGuestsLastMonth = reservations
            .filter((o) =>
              moment(o.createdDateTime, "YYYY-MM-DD").isBetween(
                moment().subtract(1, "months"),
                moment().subtract(0, "months"),
                undefined,
                "[]"
              )
            )
            .reduce((a, b) => a + b.numberofguests, 0);

          this.depositPaymentsNogCountWomen = 0;
          this.depositPaymentsNogCountMan = 0;
          this.depositPaymentsNogCountMix = 0;
          this.depositPaymentsNogCountWomen = 0;
          this.depositPaymentsNogCountWomen = 0;

          this.remnantPaymentsNogCountWomen = 0;
          this.remnantPaymentsNogCountMan = 0;
          this.remnantPaymentsNogCountMix = 0;
          this.remnantPaymentsNogCountWomen = 0;
          this.remnantPaymentsNogCountWomen = 0;

          let totalDepositPayed = 0;
          let totalRemnantPayed = 0;
          let totalDepositToPay = 0;
          let totalRemnantToPay = 0;
          this.teamRemnantPaymentsCount = 0;

          for (let i = 0; i < reservations.length; i++) {
            let RemnantPayments = 0;
            const reservation = reservations[i];

            RemnantPayments = reservation.reservationPayments
              .filter(({ status, type }) => status == 1 && type == "normal")
              .reduce((a, b) => a + b.amount, 0);

            const DepositPayed = reservation.reservationPayments
              .filter(({ status, type }) => status == 1 && type == "deposit")
              .reduce((a, b) => a + b.amount, 0);

            totalDepositPayed += DepositPayed;
            totalRemnantPayed += RemnantPayments;

            if (DepositPayed > 0) {
              this.teamDepositPaymentsCount++;

              if (reservation?.genderOfTeam == "Mannen") {
                this.depositPaymentsNogCountMan += reservation.numberofguests;
              }
              if (reservation?.genderOfTeam == "Vrouwen") {
                this.depositPaymentsNogCountWomen += reservation.numberofguests;
              }
              if (reservation?.genderOfTeam == "Mix") {
                this.depositPaymentsNogCountMix += reservation.numberofguests;
              }
            }

            if (RemnantPayments > 0) {
              this.teamRemnantPaymentsCount++;

              if (reservation?.genderOfTeam == "Mannen") {
                this.remnantPaymentsNogCountMan += reservation.numberofguests;
              }
              if (reservation?.genderOfTeam == "Vrouwen") {
                this.remnantPaymentsNogCountWomen += reservation.numberofguests;
              }
              if (reservation?.genderOfTeam == "Mix") {
                this.remnantPaymentsNogCountMix += reservation.numberofguests;
              }
            }

            if (
              reservation.status !== 0 &&
              reservation.deposit_fee &&
              reservation?.remnant_fee
            ) {
              totalDepositToPay +=
                reservation?.numberofguests * reservation?.deposit_fee?.amount;
              totalRemnantToPay +=
                reservation?.numberofguests *
                (reservation?.remnant_fee?.amount -
                  reservation?.deposit_fee?.amount);
            }
          }

          this.totalDepositPayedPercentage =
            (totalDepositPayed / totalDepositToPay) * 100;

          this.totalRemnantPayedPercentage =
            (totalRemnantPayed / totalRemnantToPay) * 100;
        },
      };
    },
  },
};
</script>
