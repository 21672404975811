<template>
  <Backdrop>
    <div
      style="width: 55%; top: 50%; left: 50%; transform: translate(-50%, -50%)"
      class="
        overflow-x-hidden overflow-y-auto
        fixed
        inset-0
        z-50
        outline-none
        focus:outline-none
        justify-center
        items-center
        flex
      "
    >
      <div class="relative w-full my-6 mx-auto">
        <div
          class="
            border-0
            rounded-lg
            shadow-lg
            relative
            flex flex-col
            w-full
            bg-white
            outline-none
            focus:outline-none
          "
        >
          <div
            class="
              flex
              items-start
              justify-between
              p-5
              border-b border-solid border-blueGray-200
              rounded-t
            "
          >
            <h3 class="text-3xl font-semibold">{{title}}</h3>
            <button
              @click="$emit('closeModal')"
              class="
                p-1
                ml-auto
                bg-transparent
                border-0
                text-black
                opacity-5
                float-right
                text-3xl
                leading-none
                font-semibold
                outline-none
                focus:outline-none
              "
            >
              <span
                class="
                  bg-transparent
                  text-black
                  opacity-5
                  h-6
                  w-6
                  text-2xl
                  block
                  outline-none
                  focus:outline-none
                "
              >
                ×
              </span>
            </button>
          </div>
          <div class="relative p-8 flex-auto">
            <div class="my-4 text-blueGray-500 text-lg leading-relaxed" v-html="text"></div>
          </div>
          <div
            class="
              flex
              items-center
              justify-end
              p-2
              border-t border-solid border-blueGray-200
              rounded-b
            "
          >
            <slot name="button"></slot>
            <button
              @click="$emit('closeModal')"
              class="
                active:bg-emerald-600
                font-bold
                uppercase
                text-sm
                px-6
                py-3
                rounded
                shadow
                hover:shadow-lg
                outline-none
                focus:outline-none
                mr-1
                mb-1
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              close
            </button>
          </div>
        </div>
      </div>
    </div>
  </Backdrop>
</template>

<script>
import Backdrop from "@/components/Elements/Backdrop.vue";

export default {
  components: {
    Backdrop,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModalTest: this.showModal,
    };
  },
};
</script>