<template>
  <CardTents @refetch="refetchTents" :tents="tents" />
  <div class="flex flex-wrap">
    <div
      class="
        relative
        flex flex-col
        min-w-0
        break-words
        w-full
        mb-6
        shadow-lg
        rounded-lg
        bg-blueGray-100
        border-0
      "
    >
      <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold">Add a tent</h6>
        </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form @submit.prevent="submitNewTent($event)">
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Tent info
          </h6>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="
                    block
                    uppercase
                    text-blueGray-600 text-xs
                    font-bold
                    mb-2
                  "
                  for="grid-password"
                >
                  Name </label
                ><input
                  type="text"
                  class="
                    border-0
                    px-3
                    py-3
                    placeholder-blueGray-300
                    text-blueGray-600
                    bg-white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none focus:ring
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  "
                  name="input-tentName"
                />
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="
                    block
                    uppercase
                    text-blueGray-600 text-xs
                    font-bold
                    mb-2
                  "
                  for="grid-password"
                >
                  Capacity text </label
                ><input
                  type="text"
                  class="
                    border-0
                    px-3
                    py-3
                    placeholder-blueGray-300
                    text-blueGray-600
                    bg-white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none focus:ring
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  "
                  placeholder="10x5 - 20 personen"
                  name="input-capacityText"
                />
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="
                    block
                    uppercase
                    text-blueGray-600 text-xs
                    font-bold
                    mb-2
                  "
                  for="grid-password"
                >
                  Price </label
                ><input
                  type="number"
                  min="0.00"
                  step="0.01"
                  name="input-tentPrice"
                  v-model="price"
                  class="
                    border-0
                    px-3dbe
                    py-3
                    placeholder-blueGray-300
                    text-blueGray-600
                    bg-white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none focus:ring
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  "
                />
              </div>
            </div>
            <div class="flex w-full lg:w-6/12 px-4">
              <div class="relative w-1/2 pr-3 mb-3">
                <label
                  class="
                    block
                    uppercase
                    text-blueGray-600 text-xs
                    font-bold
                    mb-2
                  "
                  for="grid-password"
                >
                  Weekend </label
                >
                <select class="w-full" name="input-tentWeekendId" id="input-tentWeekendId">
                  <option v-for="event in events" :value="event.id" :key="event.id">
                    {{event.locationName}} - {{ $date(event.startsAt).format("DD-MM-YYYY") }}
                  </option>
                </select>
                <!-- <input
                  type="number"
                  name="input-tentWeekendId"
                  class="
                    border-0
                    px-3
                    py-3
                    placeholder-blueGray-300
                    text-blueGray-600
                    bg-white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none focus:ring
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  "
                /> -->
              </div>
              <div class="relative w-1/2 pl-3 mb-3">
                <label
                  class="
                    block
                    uppercase
                    text-blueGray-600 text-xs
                    font-bold
                    mb-2
                  "
                  for="grid-password"
                >
                  Amount available </label
                ><input
                  type="number"
                  name="input-tentCapacity"
                  class="
                    border-0
                    px-3
                    py-3
                    placeholder-blueGray-300
                    text-blueGray-600
                    bg-white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none focus:ring
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  "
                />
              </div>
            </div>
          </div>
          <button
            class="
              mt-8
              float-right
              bg-emerald-500
              text-white
              active:bg-emerald-600
              font-bold
              uppercase
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            type="submit"
          >
            Saves
          </button>
        </form>
      </div>
    </div>
    <CardBookedTents @refetch="refetchTents" :bookedTents="bookedTents" />
    <teleport to="#modal">
      <SuccesAlert :show="showAlert" @resetShow="showAlert = false"/>
      <Modal
        v-if="openModal"
        :title="modalTitle"
        :text="modalText"
        @closeModal="openModal = false"
      />
    </teleport>
  </div>
</template>

<script>

import { AddTent } from '@/graphql/events/mutations.js';
import { getEvents, getTents, getBookedTents } from "@/graphql/events/query.js";

import CardTents from "@/components/Cards/CardTents.vue";
import CardBookedTents from "@/components/Cards/CardBookedTents.vue"; 

import Modal from "@/components/Elements/Modal.vue";
import SuccesAlert from '@/components/Elements/SuccesAlert.vue';

export default {
  data() {
    return {
      showAlert: false,
      openModal: false,
      modalTitle: '',
      modalText: ''
    };  
  },
  components: {
    CardTents,
    CardBookedTents,
    Modal,
    SuccesAlert
  },
  apollo: {
    events() {
      return {
        query: getEvents,
      };
    },
    tents() {
      return {
        query: getTents,
        variables: () => {
          return {
            weekendID: parseInt(this.$store.getters.currWeekendId)
          }
        }
      };
    },
    bookedTents() {
      return {
        query: getBookedTents,
        variables: () => {
          return {
            weekendID: parseInt(this.$store.getters.currWeekendId)
          }
        }
      };
    },
  },
  methods: {
    async submitNewTent(e) {

      let input = {
        tentName: e.target.elements["input-tentName"].value,
        capacityText: e.target.elements["input-capacityText"].value,
        tentPrice: parseFloat(e.target.elements["input-tentPrice"].value),
        tentWeekendId: parseInt(e.target.elements["input-tentWeekendId"].value),
        tentCapacity: parseInt(e.target.elements["input-tentCapacity"].value),
      };

      try {
        const res = await this.$apollo.mutate({
          mutation: AddTent,
          variables: {
            input,
          },
        });

        if (res?.data?.AddTent.status === 200) {
          this.$apollo.queries.tents.refetch();
          this.showAlert = true;
          for (let i = 0; i < e.target.elements.length; i++) {
            const inputField = e.target.elements[i];
            inputField.value = '';
          }
        } else {
          this.modalTitle = "Er ging iets mis.";
          this.modalText = res?.data?.ChangeReservationNog.statusMsg;
          this.openModal = true;
        }
      } catch (e) {
        console.error(e);
        this.modalTitle = "Er ging iets mis.";
        this.modalText = 'zijn alle velden ingevuld?';
        this.openModal = true;
      }
    },
    refetchTents() {
      this.$apollo.queries.tents.refetch();
    }
  },
};
</script>