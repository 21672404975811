<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <card-waitinglist
        :reservations="waitinglist?.reservations"
        class="min-h-full"
        :totalRes="waitinglist?.count"
        @update-list="updateList"
        @search-list="searchList"
      >
        <template v-slot:filters>
          <select
            @change="currPage = 0"
            v-model="itemsPP"
            class="ml-3"
            name="perPageSelector"
            id="perPageSelector"
          >
            <option :value="initialItemsPP">
              {{ initialItemsPP }}
            </option>
            <option v-if="waitinglist?.count > 50" value="50">50</option>
            <option v-if="waitinglist?.count > 100" value="100">100</option>
            <option :value="waitinglist?.count">
              Show all - ({{ waitinglist?.count }})
            </option>
          </select>
        </template>
        <pagination
          v-if="waitinglist?.count > itemsPP"
          @nextPage="nextPage"
          @prevPage="prevPage"
          :currPage="currPage"
          :PPage="itemsPP"
          :total="waitinglist?.count"
          :totalPages="Math.ceil(waitinglist?.count / itemsPP)"
          @toSpecificPage="toSpecificPage"
        />
      </card-waitinglist>
    </div>
  </div>
</template>
<script>
import { getWaitinglist } from "@/graphql/reservations/query.js";
import pagination from "@/components/Elements/pagination.vue";
import CardWaitinglist from "@/components/Cards/CardWaitingList.vue";

export default {
  components: {
    CardWaitinglist,
    pagination,
  },
  data() {
    return {
      currPage: 0,
      initialItemsPP: 20,
      itemsPP: 20,
      word: "",
    };
  },
  mounted() {
    this.$apollo.queries.waitinglist.refetch();
  },
  apollo: {
    waitinglist() {
      return {
        query: getWaitinglist,
        networkPolicy: "network-only",
        variables: () => {
          return {
            orderBy: "desc",
            currPage: this.currPage,
            itemsPP: parseInt(this.itemsPP),
            searchWord: this.word,
            weekendID: parseInt(this.$store.getters.currWeekendId),
          };
        },
      };
    },
  },
  methods: {
    searchList(word) {
      this.currPage = 0;
      this.word = word;
    },
    toSpecificPage(pageIndex) {
      this.currPage = pageIndex - 1;
    },
    nextPage() {
      this.currPage++;
    },
    prevPage() {
      if (this.currPage > 0) this.currPage--;
    },
    updateList() {
      this.$apollo.queries.waitinglist.refetch();
    },
  },
};
</script>
