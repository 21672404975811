import gql from 'graphql-tag';

export const updateSmsTemplate = gql`
  mutation updateSmsTemplate($input: UpdateSMSTemplateInput!) {
    updateSmsTemplate(input: $input) {
      status
      statusMsg
    }
  }
`;
