<template>
  <div>
    <CardPayments />
  </div>
</template>
<script>

import CardPayments from '@/components/Cards/CardPayments.vue';

export default {
  name: "Payment-page",
  components: {
    CardPayments
  },
};
</script>
