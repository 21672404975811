import gql from 'graphql-tag';

export const deleteUser = gql`
  mutation deleteUser($userID: Int!) {
    deleteUser(userID: $userID) {
      status
      statusMsg
    }
  }
`;

export const loginUser = gql`
  mutation ($input: LoginUserInput!) {
    loginUser(input: $input) {
      user {
        guid
        name
        surename
        email
        team_id
        main_booker
        role
      }
      status
      statusMsg
    }
  }
`;

export const logoutUser= gql`
  mutation logoutUser {
    logoutUser {
      success
    }
  }
`;