<template>
  <div>
    <a
      class="text-blueGray-500 block"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <div class="items-center flex">actions&#8675;</div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="
        bg-white
        text-base
        z-50
        float-left
        py-2
        list-none
        text-left
        rounded
        shadow-lg
        min-w-48
      "
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <div
        @click="changeGender($event, 'Vrouwen')"
        class="
          cursor-pointer
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
        "
      >
        Maak dames
      </div>
      <div
        @click="changeGender($event, 'Mannen')"
        class="
          cursor-pointer
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
        "
      >
        Maak mannen
      </div>
      <div
        @click="changeGender($event, 'Mix')"
        class="
          cursor-pointer
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
        "
      >
        Maak mix
      </div>
      <div
        @click="changeReservationStatus($event, 2)"
        class="
          cursor-pointer
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
        "
      >
        Wachtlijst
      </div>
      <div class="h-0 my-2 border border-solid border-blueGray-100" />
      <div
        @click="openRemoveResWarning($event)"
        class="
          cursor-pointer
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-red-500
        "
      >
        Res. Annuleren
      </div>
    </div>
  </div>
  <teleport to="#modal">
    <SuccesAlert :show="showAlert" @resetShow="showAlert = false"/>
    <Modal
      v-if="openModal"
      :title="modalTitle"
      :text="modalText"
      @closeModal="openModal = false"
    />
  </teleport>
  <teleport to="#modal">
    <Modal
      v-if="openWarningModal"
      :title="modalTitle"
      :text="modalText"
      @closeModal="openWarningModal = false"
    >
      <template v-slot:button>
        <div @click="changeReservationStatus($event, 0)">
          <button
            class="
              bg-red-500
              text-white
              font-normal
              px-4
              py-2
              rounded
              outline-none
              focus:outline-none
              mr-2
              mb-1
              uppercase
              inline-flex
              items-center
            "
            type="button"
          >
            Annuleren
          </button>
        </div>
      </template>
    </Modal>
  </teleport>
</template>

<script>
import { createPopper } from "@popperjs/core";

import { ChangeTeamGender } from "@/graphql/team/mutations.js";
import { ChangeReservationStatus } from "@/graphql/reservations/mutations.js";
import Modal from "@/components/Elements/Modal.vue";
import SuccesAlert from '@/components/Elements/SuccesAlert.vue';


export default {
  components: {
    Modal,
    SuccesAlert
  },
  props: {
    reservationnumber: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dropdownPopoverShow: false,
      openModal: false,
      modalTitle: "",
      modalText: "",
      openWarningModal: false,
      showAlert: false
    };
  },
  methods: {
    openRemoveResWarning(event) {
      event.preventDefault();
      event.stopPropagation();
      
      this.modalTitle = "Weet je het zeker?";
      this.modalText =
        "Je staat op het punt een reservering te Annuleren, weet je het zeker?";
      this.openWarningModal = true;
    },
    toggleDropdown: function (event) {
      event.preventDefault();
      event.stopPropagation();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    async changeGender(event, gender) {
      event.preventDefault();
      event.stopPropagation();
      try {
        const res = await this.$apollo.mutate({
          mutation: ChangeTeamGender,
          variables: {
            gender,
            reservationnumber: this.reservationnumber,
          },
        });
        if (res?.data?.ChangeTeamGender.status == 200) {
          this.$emit("update-gender", gender);
          this.dropdownPopoverShow = false;
          this.showAlert = true;
        } else {
          this.modalTitle = "Er ging iets mis.";
          this.modalText = res?.data?.ChangeTeamGender.statusMsg;
          this.openModal = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async changeReservationStatus(event, status) {
      event.preventDefault();
      event.stopPropagation();
      try {
        const res = await this.$apollo.mutate({
          mutation: ChangeReservationStatus,
          variables: {
            reservationnumber: this.reservationnumber,
            status,
          },
        });
        if (res?.data?.ChangeReservationStatus.status == 200) {
          this.$emit("update-status");
          this.dropdownPopoverShow = false;
        } else {
          this.modalTitle = "Er ging iets mis.";
          this.modalText = res?.data?.ChangeReservationStatus.statusMsg;
          this.openModal = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
