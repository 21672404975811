<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">
                Sign
              </h6>
            </div>
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form @submit.prevent="submitLogin($event)">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="input-email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="input-password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                />
              </div>

              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <teleport to="#modal">
    <Modal v-if="openModal" :title="modalTitle" :text="modalText" @closeModal="openModal = false" />
  </teleport>
</template>
<script>

import { loginUser } from '@/graphql/user/mutations.js';
import Modal from "@/components/Elements/Modal.vue";

export default {
  components: {
    Modal
  },
  data() {
    return {
      openModal: false,
      modalTitle: '',
      modalText: ''
    };
  },
  methods: {
    async submitLogin(e) {
      e.preventDefault();
      const email = e.target.elements['input-email'].value;
      const password = e.target.elements['input-password'].value;

      try {
        const res = await this.$apollo.mutate({
          mutation: loginUser,
          variables: {
            input: {
              email,
              password,
            },
          },
        });

        if (res?.data?.loginUser?.user && res?.data?.loginUser?.user.role == 'admin' || res?.data?.loginUser?.user.role == 'superAdmin') {
          this.$store.commit('setLoggedIn', true);
          this.$store.commit('setRole', res?.data?.loginUser?.user.role);
          this.$router.push({
            name: 'Admin',
          });
        }
      } catch (e) {
        this.modalTitle = 'Er ging iets mis.'
        this.modalText = e;
        this.openModal = true;
      }
    },
  }

};
</script>
