<template>
  <select
    @change="selectWeekend()"
    v-model="currWeekendId"
    name="eventSelector"
    id="eventSelector"
  >
    <option
      v-for="event in events"
      :value="event.id"
      :key="`weekend-${event.weekendID}`"
    >
      {{ $date(event.startsAt).format("YYYY") }} -
      {{ $date(event.startsAt).locale("nl").format("DD MMMM") }}
    </option>
  </select>
</template>

<script>
import { getEvents } from "@/graphql/events/query.js";

export default {
  data() {
    return {
      currWeekendId: this.$store.getters.currWeekendId
    }
  },
  apollo: {
    events() {
      return {
        query: getEvents,
        result({ data }) {
          if(!this.$store.getters.currWeekendId) {
            this.$store.commit('setCurrWeekendId', data?.events[data?.events.length - 1].id);
          }
        },
      };
    },
  },
  methods: {
    selectWeekend() {
      this.$store.commit('setCurrWeekendId', this.currWeekendId);
    },
  },
};
</script>