<template>
  <div>
    <div id="app">
      <router-view />
    </div>
    <div id="modal"></div>
  </div>
</template>
<script>
export default {
  mounted() {
    window.addEventListener("auth", (event) => {
      const isAuthenticated = event?.detail?.isAuthenticated;
      // compare strict, because variables could be undefined
      if (isAuthenticated === false) {
        this.$store.commit("setLoggedIn", false);
        this.$router.push({ name: "Auth" });
      }
    });
  },
};
</script>