<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div
    class="
      bg-white
      px-4
      py-3
      w-full
      flex
      items-center
      justify-between
      flex-row
      border-t border-gray-200
      sm:px-6
    "
  >
    <div
      class="
        w-full
        flex
        items-center
        sm:flex-1 sm:flex sm:items-center
        justify-between
      "
    >
      <div>
        <p class="text-sm text-gray-700">
          Showing
          {{ " reservation " }}
          <span class="font-medium">{{
            currPage == 0 ? 1 : currPage * PPage
          }}</span>
          {{ " " }}
          to
          {{ " " }}
          <span class="font-medium">{{
            (currPage + 1) * PPage > total ? total : (currPage + 1) * PPage
          }}</span>
          {{ " " }}
          of
          {{ " " }}
          <span class="font-medium">{{ total }}</span>
          {{ " " }}
          results
        </p>
      </div>
      <div>
        <nav
          class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <div
            v-if="currPage > 0"
            @click="prevPage"
            class="
              relative
              inline-flex
              items-center
              px-2
              py-2
              rounded-l-md
              border border-gray-300
              bg-white
              text-sm
              font-medium
              text-gray-500
              hover:bg-gray-50
            "
          >
            <span>Previous</span>
          </div>
          <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
          <div
            v-for="pageIndex in totalPages"
            :key="`pagination-${pageIndex}`"
            aria-current="page"
            @click="toSpecificPage(pageIndex)"
            class="
              z-10
              bg-indigo-50
              border-indigo-500
              text-indigo-600
              relative
              inline-flex
              items-center
              px-4
              py-2
              border
              text-sm
              font-medium
              cursor-pointer
            "
          >
            {{pageIndex}}
          </div>
          <!-- <a
            href="#"
            class="
              bg-white
              border-gray-300
              text-gray-500
              hover:bg-gray-50
              relative
              inline-flex
              items-center
              px-4
              py-2
              border
              text-sm
              font-medium
            "
          >
            2
          </a>
          <a
            href="#"
            class="
              bg-white
              border-gray-300
              text-gray-500
              hover:bg-gray-50
              hidden
              md:inline-flex
              relative
              items-center
              px-4
              py-2
              border
              text-sm
              font-medium
            "
          >
            3
          </a> -->
          <!-- <span
            v-if="totalPages > 9"
            class="
              relative
              inline-flex
              items-center
              px-4
              py-2
              border border-gray-300
              bg-white
              text-sm
              font-medium
              text-gray-700
            "
          >
            ...
          </span> -->
          <!-- <a
            href="#"
            class="
              bg-white
              border-gray-300
              text-gray-500
              hover:bg-gray-50
              hidden
              md:inline-flex
              relative
              items-center
              px-4
              py-2
              border
              text-sm
              font-medium
            "
          >
            8
          </a>
          <a
            href="#"
            class="
              bg-white
              border-gray-300
              text-gray-500
              hover:bg-gray-50
              relative
              inline-flex
              items-center
              px-4
              py-2
              border
              text-sm
              font-medium
            "
          >
            9
          </a>
          <a
            href="#"
            class="
              bg-white
              border-gray-300
              text-gray-500
              hover:bg-gray-50
              relative
              inline-flex
              items-center
              px-4
              py-2
              border
              text-sm
              font-medium
            "
          >
            10
          </a> -->
          <div
            v-if="currPage + 1 < Math.ceil(total / PPage)"
            @click="nextPage"
            class="
              relative
              inline-flex
              items-center
              px-2
              py-2
              rounded-r-md
              border border-gray-300
              bg-white
              text-sm
              font-medium
              text-gray-500
              hover:bg-gray-50
            "
          >
            <span>Next</span>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
    },
    totalPages: {
      type: Number,
    },
    currPage: {
      type: Number,
    },
    PPage: {
      type: Number,
    },
  },
  methods: {
    toSpecificPage(pageIndex) {
      this.$emit("toSpecificPage", pageIndex);
    },
    nextPage() {
      this.$emit("nextPage");
    },
    prevPage() {
      this.$emit("prevPage");
    },
  },
};
</script>