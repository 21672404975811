import gql from 'graphql-tag';

export const getEvents = gql`
  query Events {
    events {
      id
      locationName
      mapsUrl
      reserveUponApproval
      maxReserveDateTime
      startsAt
      endsAt
    }
  }
`;

export const getEventPaymetsStats = gql`
  query eventPaymentsStats($weekendID: Int!) {
    eventPaymentsStats(weekendID: $weekendID) {
      reservations {
        id
        reservationnumber
        createdDateTime
        payment_status
        deposit_feeID
        normal_feeID
        genderOfTeam
        status
        numberofguests
        deposit_fee {
          amount
        }
        remnant_fee {
          amount
        }
        reservationPayments {
          amount
          status
          type
          voucherGuid
        }
        team {
          name
        }
      }
      count
    }
  }
`;

export const getEventStats = gql`
  query eventStats($weekendID: Int!) {
    eventStats(weekendID: $weekendID) {
      reservations {
        id
        reservationnumber
        createdDateTime
        payment_status
        deposit_feeID
        normal_feeID
        genderOfTeam
        status
        numberofguests
        deposit_fee {
          amount
        }
        remnant_fee {
          amount
        }
        reservationPayments {
          amount
          status
          type
        }
      }
      count
    }
  }
`;

export const getEventCompairStats = gql`
  query eventCompairStats($weekendID: Int!) {
    eventCompairStats(weekendID: $weekendID) {
      reservations {
        weekendID
        genderOfTeam
        status
        createdDateTime
        numberofguests
      }
      prevWeekendId
      prevWeekendDate
      currWeekendId
      currWeekendDate
    }
  }
`;

export const getEventReservationWindow = gql`
  query eventReservationWindow($weekendID: Int!) {
    eventReservationWindow(weekendID: $weekendID) {
      startDate
      endDate
    }
  }
`;

export const getEventWeeklyStats = gql`
  query eventWeeklyStats($weekendID: Int!, $weekNr: Int!, $year: String!) {
    eventWeeklyStats(weekendID: $weekendID, weekNr: $weekNr, year: $year) {
      reservations {
        weekendID
        genderOfTeam
        status
        createdDateTime
        numberofguests
      }
    }
  }
`;

export const getTents = gql`
  query tents($weekendID: Int!) {
    tents(weekendID: $weekendID) {
      id
      quantity
      tent {
        id
        name
        price
        capacity_text
      }
    }
  }
`;

export const getBookedTents = gql`
  query bookedTents($weekendID: Int!) {
    bookedTents(weekendID: $weekendID) {
      id
      quantity
      createdAt
      reservation {
        team {
          name
          main_booker_user {
            name
            surename
          }
        }
      }
      tent_option {
        id
        name
        price
        capacity_text
      }
    }
  }
`;