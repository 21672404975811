import gql from 'graphql-tag';

export const AddEvent = gql`
  mutation AddEvent($input: AddEventInput!) {
    AddEvent(input: $input) {
      status
      statusMsg
    }
  }
`;

export const RemoveEvent = gql`
  mutation RemoveEvent($eventId: Int!) {
    RemoveEvent(eventId: $eventId) {
      status
      statusMsg
    }
  }
`;

export const AddTent = gql`
  mutation AddTent($input: AddTentInput!) {
    AddTent(input: $input) {
      status
      statusMsg
    }
  }
`;

export const RemoveTent = gql`
  mutation RemoveTent($tentId: Int!) {
    RemoveTent(tentId: $tentId) {
      status
      statusMsg
    }
  }
`;