import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

// Store things
import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const vuexLocal = new VuexPersistence({
  key: 'ra-storage',
  storage: window.localStorage,
});

const store = new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
});

export default store;
