<template>
  <div class="flex flex-wrap">
    <div
      class="
        relative
        flex flex-col
        min-w-0
        break-words
        w-full
        mb-6
        shadow-lg
        rounded-lg
        bg-blueGray-100
        border-0
      "
    >
      <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold">Reservering toevoegen</h6>
        </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form ref="form" class="mt-5" @submit.prevent="submitRes">
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="grid-first-name"
              >
                Voornaam
              </label>
              <input
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
                v-model="input.name"
                id="grid-first-name"
                type="text"
                placeholder="Jane"
              />
            </div>
            <div class="w-1/2 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="grid-last-name"
              >
                Achternaam
              </label>
              <input
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
                v-model="input.surename"
                id="grid-last-name"
                type="text"
                placeholder="Doe"
              />
            </div>

            <div class="w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="grid-team-name"
              >
                teamname
              </label>
              <input
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
                v-model="input.teamname"
                id="grid-team-name"
                type="text"
                placeholder="FC Bal op het dak"
              />
            </div>
            <div class="w-1/2 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="grid-email"
              >
                email
              </label>
              <input
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
                v-model="input.email"
                id="grid-email"
                type="email"
                placeholder="voorbeeld@email.nl"
              />
            </div>

            <div class="w-1/2 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="grid-phone"
              >
                phone
              </label>
              <input
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
                v-model="input.phone"
                id="grid-phone"
                type="tel"
                placeholder="0612345678"
              />
            </div>

            <div class="w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="grid-comment"
              >
                comment
              </label>
              <input
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
                v-model="input.comment"
                id="grid-comment"
                type="text"
                placeholder="Extra info"
              />
            </div>
            <div class="w-1/2 px-3 mb-6 md:mb-0">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="grid-voucherGuid"
              >
                voucherGuid
              </label>
              <input
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
                v-model="input.voucherGuid"
                id="grid-voucherGuid"
                type="text"
                placeholder="voucher code"
              />
            </div>

            <div class="w-1/2 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="grid-genderOfTeam"
              >
                genderOfTeam
              </label>
              <select
                class="
                  block
                  appearance-none
                  w-full
                  bg-white
                  border border-gray-400
                  hover:border-gray-500
                  px-4
                  py-2
                  pr-8
                  rounded
                  shadow
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                id="grid-genderOfTeam"
                v-model="input.genderOfTeam"
              >
                <option>Mannen</option>
                <option>Vrouwen</option>
                <option>Mix</option>
              </select>
            </div>
            <div class="w-1/2 px-3">
              <label
                class="
                  block
                  uppercase
                  tracking-wide
                  text-gray-700 text-xs
                  font-bold
                  mb-2
                "
                for="grid-nog"
              >
                Aantal mensen
              </label>
              <input
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
                v-model="input.numberofguests"
                id="grid-nog"
                type="number"
                placeholder="11"
              />
            </div>
            <div class="w-1/2 flex flex-col">
              <div class="flex mb-6">
                <div class="w-1/3 px-3">
                  <label
                    class="
                      block
                      uppercase
                      tracking-wide
                      text-gray-700 text-xs
                      font-bold
                      mb-2
                    "
                    for="grid-nog"
                  >
                    Deposit betaald
                  </label>
                  <input
                    style="width: 20px; height: 20px"
                    v-model="input.depositPaid"
                    id="grid-nog"
                    type="checkbox"
                  />
                </div>

                <div class="w-1/3 px-3">
                  <label
                    class="
                      block
                      uppercase
                      tracking-wide
                      text-gray-700 text-xs
                      font-bold
                      mb-2
                    "
                    for="grid-nog"
                  >
                    Normal betaald (full)
                  </label>
                  <input
                    style="width: 20px; height: 20px"
                    v-model="input.normalPaid"
                    id="grid-nog"
                    type="checkbox"
                  />
                </div>
                <div class="w-1/3 px-3">
                  <label
                    class="
                      block
                      uppercase
                      tracking-wide
                      text-gray-700 text-xs
                      font-bold
                      mb-2
                    "
                    for="grid-nog"
                  >
                    Splitt VIP
                  </label>
                  <input
                    style="width: 20px; height: 20px"
                    v-model="input.splittVip"
                    id="grid-nog"
                    type="checkbox"
                  />
                </div>
              </div>
              <div
                class="
                  items-end
                  px-3
                  overflow-hidden
                  transition-all
                  duration-1000
                "
                :class="!input.splittVip ? 'max-h-0' : ''"
              >
                <label
                  class="
                    block
                    uppercase
                    tracking-wide
                    text-gray-700 text-xs
                    font-bold
                    mb-2
                  "
                  for="grid-nog"
                >
                  Borg bedrag
                </label>
                <input
                  class="
                    border-0
                    px-3
                    py-3
                    placeholder-blueGray-300
                    text-blueGray-600
                    bg-white
                    rounded
                    text-sm
                    shadow
                    focus:outline-none focus:ring
                    w-full
                    ease-linear
                    transition-all
                    duration-150
                  "
                  v-model="input.guarantorAmount"
                  id="grid-nog"
                  type="number"
                  placeholder="11"
                />
              </div>
            </div>
          </div>
          <button
            class="
              mt-8
              float-right
              bg-emerald-500
              text-white
              active:bg-emerald-600
              font-bold
              uppercase
              px-4
              py-2
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
            type="submit"
          >
            Reserveren
          </button>
        </form>
      </div>
    </div>
  </div>
  <teleport to="#modal">
    <SuccesAlert :show="showAlert" @resetShow="showAlert = false" />
    <Modal
      v-if="openModal"
      :title="modalTitle"
      :text="modalText"
      @closeModal="openModal = false"
    >
      <template #button>
        <button
        v-if="showSendButton"
          @click="sendSms"
          class="
            bg-emerald-500
            active:bg-emerald-600
            uppercase
            text-white
            font-bold
            hover:shadow-md
            shadow
            text-xs
            px-4
            py-2
            rounded
            outline-none
            focus:outline-none
            sm:mr-2
            mb-1
            ease-linear
            transition-all
            duration-150
          "
        >
          SEND
        </button>
      </template>
    </Modal>
  </teleport>
</template>
  
  <script>
import { AdminCreateReservation } from "@/graphql/reservations/mutations.js";

import SuccesAlert from "@/components/Elements/SuccesAlert.vue";
import Modal from "@/components/Elements/Modal.vue";

export default {
  name: "resForm",
  data() {
    return {
      sending: false,
      showAlert: false,
      openModal: false,
      modalTitle: '',
      modalText: '',
      input: {
        genderOfTeam: null,
        teamname: null,
        name: null,
        surename: null,
        email: null,
        phone: null,
        comment: "Reservation from admin pannel",
        voucherGuid: "",
        numberofguests: null,
        depositPaid: false,
        normalPaid: false,
        splittVip: false,
        guarantorAmount: 30,
      },
    };
  },
  components: {
    SuccesAlert,
    Modal,
  },
  methods: {
    async submitRes() {
      if (this.sending) return;
      this.sending = true;
      const input = {
        genderOfTeam: this.input.genderOfTeam,
        teamname: this.input.teamname,
        name: this.input.name,
        surename: this.input.surename,
        email: this.input.email,
        phone: this.input.phone,
        comment: this.input.comment,
        voucherGuid: this.input.voucherGuid,
        numberofguests: parseInt(this.input.numberofguests),
        weekendID: parseInt(this.$store.getters.currWeekendId),
        depositPaid: this.input.depositPaid,
        normalPaid: this.input.normalPaid,
        splittVip: this.input.splittVip,
        guarantorAmount: this.input.splittVip ? this.input.guarantorAmount : 0
      };

      for (const key in input) {
        if (input[key] == null) {
          alert("missing fields");
          this.sending = false;
          return;
        }
      }
      try {
        const res = await this.$apollo.mutate({
          mutation: AdminCreateReservation,
          variables: {
            input,
          },
        });
        if (res?.data?.adminCreateReservation.status == 200) {
          this.input.genderOfTeam = null;
          this.input.teamname = null;
          this.input.name = null;
          this.input.surename = null;
          this.input.email = null;
          this.input.phone = null;
          this.input.comment = "Reservation from admin pannel";
          this.input.voucherGuid = "";
          this.input.numberofguests = null;
          this.input.depositPaid = false;
          this.input.normalPaid = false;
          this.input.splittVip = false;
          this.showAlert = true;
          this.openModal = false;
          this.sending = false;
        } else {
          this.modalTitle = "Er ging iets mis.";
          this.modalText = res?.data?.SendSms.statusMsg;
          this.openModal = false;
        }
      } catch (e) {
        console.error(e);
        this.errorMsg = e;
        this.modalTitle = "Er ging iets mis.";
        this.modalText = e;
        this.openModal = true;
      }
    },
  },
};
</script>