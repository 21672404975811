import gql from 'graphql-tag';

export const ChangeVoucher = gql`
  mutation ChangeVoucher($input: ChangeVoucherInput!) {
    ChangeVoucher(input: $input) {
      status
      statusMsg
    }
  }
`;

export const AddVoucher = gql`
  mutation AddVoucher($input: AddVoucherInput!) {
    AddVoucher(input: $input) {
      status
      statusMsg
    }
  }
`;