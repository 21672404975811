import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";

import { apolloProvider } from '@/apollo/provider.js';

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

// Store
import store from './store';
App.store = store;

// layouts
import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout
import Dashboard from "@/views/admin/Dashboard.vue";
import Payments from "@/views/admin/Payments.vue";
import Approve from "@/views/admin/Approve.vue";
import Reservations from "@/views/admin/Reservations.vue";
import Waitlist from "@/views/admin/Waitlist.vue";
import Events from "@/views/admin/Events.vue";
import Tents from "@/views/admin/Tents.vue";
import Sms from "@/views/admin/Sms.vue";
import ReservationForm from "@/views/admin/ReservationForm.vue";


// views for Auth layout
import Login from "@/views/auth/Login.vue";

// Set date library
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import 'dayjs/locale/en';
import 'dayjs/locale/de';

// routes
// @TODO make router.js file
const routes = [
  {
    path: "/admin",
    name: "Admin",
    redirect: "/admin/dashboard",
    component: Admin,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/admin/dashboard",
        component: Dashboard,
      },
      {
        path: "/admin/payments",
        component: Payments,
      },
      {
        path: "/admin/approve",
        component: Approve,
      },
      {
        path: "/admin/reserveringen",
        component: Reservations,
      },
      {
        path: "/admin/waitlist",
        component: Waitlist,
      },
      {
        path: "/admin/events",
        component: Events,
      },
      {
        path: "/admin/tents",
        component: Tents,
      },
      {
        path: "/admin/sms",
        component: Sms,
      },
      {
        path: "/admin/reservationForm",
        component: ReservationForm,
      }
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/login",
        name: "Auth",
        component: Login,
      },
    ],
  },
  {
    path: "/",
    name: "landing",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/login",
        component: Login,
      },
    ],
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    to.name != 'Auth'
  ) {
    if (!store.getters.isLoggedIn) {
      next({ name: 'Auth' });
    } else {
      next();
    }
  } else {
    if (store.getters.isLoggedIn && to.name == 'Auth') {
      next({ name: 'Admin' });
    } else {
      next();
    }
  }
});
const app = createApp(App).use(store).use(router).use(apolloProvider);

Object.defineProperties(app.config.globalProperties, {
  $date: { get: () => dayjs },
});

app.mount("#app");