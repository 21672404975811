import gql from 'graphql-tag';

export const ChangeTeamInfo = gql`
  mutation ChangeTeamInfo($input: ChangeTeamInfoInput!) {
    ChangeTeamInfo(input: $input) {
      status
      statusMsg
    }
  }
`;

export const ChangeTeamGender = gql`
  mutation ChangeTeamGender($gender: String!, $reservationnumber: String!) {
    ChangeTeamGender(gender: $gender, reservationnumber: $reservationnumber) {
      status
      statusMsg
    }
  }
`;

export const removeMember = gql`
  mutation removeMember($guid: String!, $reservationnumber: String!, $teamID: Int!) {
    removeMember(guid: $guid, reservationnumber: $reservationnumber, teamID: $teamID) {
      status
      statusMsg
    }
  }
`;

export const activateUser = gql`
  mutation activateUser($guid: String!) {
    activateUser(guid: $guid) {
      status
      statusMsg
    }
  }
`;