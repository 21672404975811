<template>
  <div class="flex flex-wrap mt-4">
    <div id="popup-target"></div>
    <div class="w-full mb-12 px-4">
      <card-reservations
        :reservations="PaginatedReservations?.reservations"
        :totalRes="PaginatedReservations?.count"
        @search-list="searchList"
        class="min-h-full"
      >
        <template v-slot:filters>
          <select
            @change="currPage = 0"
            v-model="itemsPP"
            class="ml-3"
            name="perPageSelector"
            id="perPageSelector"
          >
            <option :value="initialItemsPP">
              {{ initialItemsPP }}
            </option>
            <option v-if="PaginatedReservations?.count > 50" value="50">
              50
            </option>
            <option v-if="PaginatedReservations?.count > 100" value="100">
              100
            </option>
            <option :value="PaginatedReservations?.count">
              Show all - ({{ PaginatedReservations?.count }})
            </option>
          </select>
        </template>
        <pagination
          v-if="PaginatedReservations?.count > itemsPP"
          @nextPage="nextPage"
          @prevPage="prevPage"
          :currPage="currPage"
          :PPage="itemsPP"
          :total="PaginatedReservations?.count"
          :totalPages="Math.ceil(PaginatedReservations?.count / itemsPP)"
          @toSpecificPage="toSpecificPage"
        />
      </card-reservations>

    </div>
  </div>
</template>

<script>
import { getPaginatedReservations } from "@/graphql/reservations/query.js";
import CardReservations from "@/components/Cards/CardReservations.vue";
import Pagination from "@/components/Elements/pagination.vue";

export default {
  components: {
    CardReservations,
    Pagination
  },
  data() {
    return {
      currPage: 0,
      initialItemsPP: 20,
      itemsPP: 20,
      word: "",
    };
  },
  mounted() {
    this.$apollo.queries.PaginatedReservations.refetch();
  },
  methods: {
    closeModal() {
      this.openModal = true;
    },
    searchList(word) {
      this.currPage = 0;
      this.word = word;
    },
    toSpecificPage(pageIndex) {
      this.currPage = pageIndex - 1;
    },
    nextPage() {
      this.currPage++;
    },
    prevPage() {
      if (this.currPage > 0) this.currPage--;
    },
  },
  apollo: {
    PaginatedReservations() {
      return {
        query: getPaginatedReservations,
        fetchPolicy: "no-cache",
        variables: () => {
          return {
            currPage: this.currPage,
            itemsPP: parseInt(this.itemsPP),
            searchWord: this.word,
            weekendID: parseInt(this.$store.getters.currWeekendId),
          };
        },
      };
    },
  },
};
</script>
