<template>
  <div
    ref="alertPopUp"
    class="
      alert
      fixed
      z-50
      w-auto
      border-t-4
      rounded-b
      px-4
      pl-4
      py-3
      shadow-md
      bg-teal-100
      border-teal-500
      text-teal-900
    "
    role="alert"
  >
    <div class="flex items-center">
      <div class="py-1 pr-4">
        <svg
          class="checkmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            class="checkmark__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            class="checkmark__check"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
      </div>
      <div>
        <p class="font-bold">{{title}}</p>
        <p class="text-sm">{{text}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean
    },
    title: {
      type: String,
      default: 'Gelukt!'
    },
    text: {
      type: String,
      default: 'De wijziging is doorgevoerd.'
    }
  },
  watch: {
    show: function (newVal) {
      if (newVal) {
        setTimeout(() => this.$refs.alertPopUp.classList.add('active'), 500);
        setTimeout(() => {
          this.$refs.alertPopUp.classList.remove('active');
          this.$emit('resetShow');
        }, 3500);
      }
    },
  },
};
</script>

<style>
.alert {
  left: -20vw;
  width: 20vw;
  top: 120px;
  transition: left 0.5s;
}

.alert.active {
  left: 20px;
}

.active .checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation-delay: 0.6s;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.active .checkmark {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation-delay: 0.6s;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.active .checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation-delay: 0.6s;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

@media screen and (max-width: 992px) {
  .alert {
    left: -45vw;
    width: 45vw;
  }
}

@media screen and (max-width: 428px) {
  .alert {
    left: -65vw;
    width: 65vw;
  }
}
</style>
