<template>
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded
    "
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative flex w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            Payments overview
          </h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Datum
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Teamnaam
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Nog te voldoen - ticket
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Aanbetaling - voucher - maar geen restant betaling
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Aanbetaling - cash - maar geen restant betaling
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Aanbetaling - voucher
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Aanbetaling - cash
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Restant/Geheel - voucher
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Restant - cash
            </th>
            <th
              class="
                px-6
                align-middle
                border border-solid
                py-3
                text-xs
                uppercase
                border-l-0 border-r-0
                whitespace-nowrap
                font-semibold
                text-left
              "
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Tent - cash
            </th>
          </tr>
        </thead>
        <tbody>
          <PaymentItem
            v-for="(reservation, index) in eventPaymentsStats?.reservations"
            :key="`res-${index}`"
            :reservation="reservation"
            @calc-DepositVoucherNoRemnant="calcDepositVoucherNoRemnant"
            @calc-DepositNoRemnant="calcDepositNoRemnant"
          />
        </tbody>
        <tr>
          <th
            class="
              border-t-0
              pr-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            <span class="ml-3 font-bold">
              <!-- spacer -->
            </span>
          </th>
          <th
            class="
              border-t-0
              pr-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            <span class="ml-3 font-bold">
              <!-- spacer -->
            </span>
          </th>
          <th
            class="
              border-t-0
              pr-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            <span class="ml-3 font-bold">
              <b
                >&euro;
                {{
                  formattedCurrency(
                    nogTotal * (remnant_feeToCalc / nogTotal) - payedAmountTotal
                  )
                }}</b
              >
            </span>
          </th>
          <th
            class="
              border-t-0
              pr-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            <span class="ml-3 font-bold">
              <b>&euro; {{ formattedCurrency(DepositVoucherNoRemnant) }}</b>
            </span>
          </th>
          <th
            class="
              border-t-0
              pr-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            <span class="ml-3 font-bold">
              <b>&euro; {{ formattedCurrency(DepositNoRemnant) }}</b>
            </span>
          </th>
          <th
            class="
              border-t-0
              pr-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            <span class="ml-3 font-bold">
              <b
                >&euro;
                {{ formattedCurrency(depositPayedWithVoucherAmountTotal) }}</b
              >
            </span>
          </th>
          <th
            class="
              border-t-0
              pr-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            <span class="ml-3 font-bold">
              <b>&euro; {{ formattedCurrency(depositPayedNoVoucherTotal) }}</b>
            </span>
          </th>
          <th
            class="
              border-t-0
              pr-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            <span class="ml-3 font-bold">
              <b
                >&euro;
                {{ formattedCurrency(remanantPayedWithVoucherAmountTotal) }}</b
              >
            </span>
          </th>
          <th
            class="
              border-t-0
              pr-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            <span class="ml-3 font-bold">
              <b>&euro; {{ formattedCurrency(remanantPayedNoVoucherTotal) }}</b>
            </span>
          </th>
          <th
            class="
              border-t-0
              pr-6
              align-middle
              border-l-0 border-r-0
              text-xs
              whitespace-nowrap
              p-4
              text-left
            "
          >
            <span class="ml-3 font-bold">
              <b>&euro; {{ formattedCurrency(tentPayedAmountTotal) }}</b>
            </span>
          </th>
        </tr>
      </table>
    </div>
  </div>
</template>
<script setup>
import formattedCurrency from "@/formattedCurrency.js";
</script>
<script>
import PaymentItem from "@/components/Elements/PaymentItem.vue";
import { getEventPaymetsStats } from "@/graphql/events/query.js";

export default {
  components: {
    PaymentItem,
  },
  data() {
    return {
      searchWord: "",
      remnant_feeToCalc: 0,
      nogTotal: 0,
      payedAmountTotal: 0,
      tentPayedAmountTotal: 0,
      depositPayedNoVoucherTotal: 0,
      remanantPayedNoVoucherTotal: 0,
      depositPayedWithVoucherAmountTotal: 0,
      remanantPayedWithVoucherAmountTotal: 0,

      DepositVoucherNoRemnant: 0,
      DepositNoRemnant: 0,
    };
  },
  mounted() {
    console.log(formattedCurrency);
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  apollo: {
    eventPaymentsStats() {
      return {
        query: getEventPaymetsStats,
        variables: () => {
          return {
            weekendID: parseInt(this.$store.getters.currWeekendId),
          };
        },
        skip: () =>
          !this.$store.getters.currWeekendId,
        result({ data }) {
          const reservations = data?.eventPaymentsStats?.reservations;

          for (let resIndex = 0; resIndex < reservations.length; resIndex++) {
            this.nogTotal += reservations[resIndex].numberofguests;
            this.remnant_feeToCalc +=
              reservations[resIndex].remnant_fee?.amount *
              reservations[resIndex].numberofguests;
            for (
              let paymentIndex = 0;
              paymentIndex < reservations[resIndex].reservationPayments?.length;
              paymentIndex++
            ) {
              const reservationPayment =
                reservations[resIndex].reservationPayments[paymentIndex];
              if (reservationPayment.status === 1) {
                if (
                  reservations[resIndex].reservationPayments[paymentIndex]
                    .type !== "tent"
                ) {
                  this.payedAmountTotal += reservationPayment.amount;

                  if (reservationPayment.voucherGuid) {
                    if (reservationPayment.type == "deposit") {
                      this.depositPayedWithVoucherAmountTotal +=
                        reservationPayment.amount;
                    } else if (reservationPayment.type == "normal") {
                      this.remanantPayedWithVoucherAmountTotal +=
                        reservationPayment.amount;
                    }
                  } else {
                    if (reservationPayment.type == "deposit") {
                      this.depositPayedNoVoucherTotal +=
                        reservationPayment.amount;
                    } else if (reservationPayment.type == "normal") {
                      this.remanantPayedNoVoucherTotal +=
                        reservationPayment.amount;
                    }
                  }
                } else {
                  console.log(
                    "reservations[resIndex].reservationPayments[paymentIndex]",
                    reservations[resIndex].reservationPayments[paymentIndex]
                      .amount
                  );
                  this.tentPayedAmountTotal +=
                    reservations[resIndex].reservationPayments[
                      paymentIndex
                    ].amount;
                }
              }
            }
          }
        },
      };
    },
  },
  methods: {
    calcDepositVoucherNoRemnant(val) {
      this.DepositVoucherNoRemnant += val;
    },
    calcDepositNoRemnant(val) {
      this.DepositNoRemnant += val;
    },
    onEnter() {
      this.$emit("search-list", this.searchWord);
    },
    updateList() {
      this.$emit("update-list");
    },
  },
};
</script>
