import gql from 'graphql-tag';

export const getSmsTemplates = gql`
  query getAllSmsTemplatesQuerySchema {
    AllSmsTemplates {
      id
      name
      originator
      body
    }
  }
`;

export const sendSms = gql`
  query sendSms($weekendID: ID!, $receivers: String!, $body: String!) {
    SendSms(weekendID: $weekendID, receivers: $receivers, body: $body) {
      status
      statusMsg
    }
  }
`;