<template>
  <CardEvents @refetch="refetchEvents" :events="events" />
  <div
    class="
      relative
      flex flex-col
      min-w-0
      break-words
      w-full
      mb-6
      shadow-lg
      rounded-lg
      bg-blueGray-100
      border-0
    "
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Add new event</h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10">
      <form @submit="addEvent($event)">
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Event Information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-password"
              >
                Event location name </label
              ><input
                v-model="newLocationName"
                type="text"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4 flex" style="gap: 1rem">
            <div class="relative w-full lg:w-6/12 mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-password"
              >
                startDateTime </label
              ><input
                v-model="newStartDateTime"
                type="datetime-local"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
            <div class="relative w-full lg:w-6/12 mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-password"
              >
                endDateTime </label
              ><input
                v-model="newEndDateTime"
                type="datetime-local"
                class="
                  border-0
                  px-3
                  py-3
                  placeholder-blueGray-300
                  text-blueGray-600
                  bg-white
                  rounded
                  text-sm
                  shadow
                  focus:outline-none focus:ring
                  w-full
                  ease-linear
                  transition-all
                  duration-150
                "
              />
            </div>
          </div>
        </div>
        <button
          class="
            mt-8
            float-right
            bg-emerald-500
            text-white
            active:bg-emerald-600
            font-bold
            uppercase
            px-4
            py-2
            rounded
            shadow
            hover:shadow-md
            outline-none
            focus:outline-none
            mr-1
            ease-linear
            transition-all
            duration-150
          "
          type="submit"
        >
          Saves
        </button>
      </form>
    </div>
  </div>
  <teleport to="#modal">
    <SuccesAlert :show="showAlert" @resetShow="showAlert = false"/>
    <Modal
      v-if="openModal"
      :title="modalTitle"
      :text="modalText"
      @closeModal="openModal = false"
    />
  </teleport>
</template>

<script>
import { getEvents } from "@/graphql/events/query.js";
import { AddEvent } from "@/graphql/events/mutations.js";

import CardEvents from "@/components/Cards/CardEvents.vue";

import Modal from "@/components/Elements/Modal.vue";
import SuccesAlert from '@/components/Elements/SuccesAlert.vue';

export default {
  components: {
    CardEvents,
    Modal,
    SuccesAlert
  },
  data() {
    return {
      newLocationName: "",
      newStartDateTime: "",
      newEndDateTime: "",
      openModal: false,
      modalTitle: "",
      modalText: "",
      openWarningModal: false,
      showAlert: false
    };
  },
  apollo: {
    events() {
      return {
        query: getEvents,
        result({ data }) {
          console.log(data);
        },
      };
    },
  },
  methods: {
    async addEvent(event) {
      event.preventDefault();
      event.stopPropagation();

      let input = {
        locationName: this.newLocationName,
        startDateTime: new Date(this.newStartDateTime),
        endDateTime: new Date(this.newEndDateTime),
      };

      try {
        const res = await this.$apollo.mutate({
          mutation: AddEvent,
          variables: {
            input,
          },
        });

        if (res?.data?.AddEvent.status === 200) {
          this.$apollo.queries.events.refetch();
          this.showAlert = true;
          this.newLocationName = '';
          this.newStartDateTime = '';
          this.newEndDateTime = '';
        } else {
          this.modalTitle = "Er ging iets mis.";
          this.modalText = res?.data?.ChangeReservationNog.statusMsg;
          this.openModal = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
    refetchEvents() {
      this.$apollo.queries.events.refetch();
    }
  },
};
</script>