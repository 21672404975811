<template>
    <div class="w-full h-full relative" style="z-index: 1072">
        <slot></slot>
    </div>
    <div class="w-full h-full top-0 right-0 modal-backdrop fade show fixed"></div>
</template>


<style scope>
.modal-backdrop.fade {
  pointer-events: none;
  opacity: 0;
  transition: opacity .15s linear;
}

.modal-backdrop.fade.show {
  pointer-events: auto;
  opacity: 0.8;
}

.modal-backdrop {
  z-index: 1071;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
</style>