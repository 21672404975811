<template>
  <tr
    v-if="reservationObj"
  >
    <th
      class="
        border-t-0
        pr-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
        text-left
      "
    >
      <span
        class="ml-3 font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        {{ $date(reservationObj.createdDateTime).format("DD-MM-YYYY HH:mm") }}
      </span>
    </th>
    <td
      class="
        border-t-0
        px-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
      "
    >
      <span
        class="font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        {{reservationObj?.team?.name}}
      </span>
    </td>
    <td
      class="
        border-t-0
        px-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
      "
    >
      <span
        class="font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
       {{ reservationObj?.team?.main_booker_user?.name }} {{reservationObj?.team?.main_booker_user?.surename }}
      </span>
    </td>
    <td
      class="
        border-t-0
        px-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
      "
    >
      {{ reservationObj?.team?.main_booker_user?.phone }}
    </td>
    <td
      class="
        border-t-0
        px-6
        align-middle
        border-l-0 border-r-0
        text-xs
        p-4
        max-w-3xl
      "
      style="max-width: 200px"
    >
      {{ reservationObj?.remarks }}
    </td>
    <td
      class="
        border-t-0
        px-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
      "
    >
      <span
        class="font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        {{ reservationObj?.numberofguests }}
      </span>
    </td>
    <td
      class="
        border-t-0
        px-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
      "
    >
      <div class="flex items-center">
        <span class="mr-2">{{ percentagePaid }}%</span>
        <div class="relative w-full">
          <div class="overflow-hidden h-2 text-xs flex rounded bg-red-200">
            <div
              :style="`width: ${percentagePaid}%`"
              class="
                shadow-none
                flex flex-col
                text-center
                whitespace-nowrap
                text-white
                justify-center
              "
              :class="backgroundColorOnPercentage()"
            ></div>
          </div>
        </div>
      </div>
    </td>
    <td
      class="
        border-t-0
        px-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
      "
    >
      <div
        v-if="
          payedAmount >=
          reservationObj?.deposit_fee.amount * reservationObj?.numberofguests
        "
      >
        <i class="fas fa-circle text-emerald-500 mr-2"></i> Volledig
      </div>
      <div
        v-else-if="
          payedAmount > 0
        "
      >
        <i class="fas fa-circle text-orange-500 mr-2"></i> Pending
      </div>
      <div v-else><i class="fas fa-circle text-red-500 mr-2"></i> Pending</div>
      <!-- <i v-else-if="payedAmount >= reservationObj?.deposit_fee.amount" class="fas fa-circle text-orange-500 mr-2"></i> Aanbetaald -->
    </td>
    <td
      class="
        border-t-0
        pl-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
      "
    >
      <span
        class="font-bold"
        :class="[color === 'light' ? 'text-blueGray-600' : 'text-white']"
      >
        {{gender}}
      </span>
    </td>
    <td
      class="
        border-t-0
        pl-6
        align-middle
        border-l-0 border-r-0
        text-xs
        whitespace-nowrap
        p-4
      "
    >
      <div class="w-full flex flex-nowrap items-center">
        <div class="w-1/2 flex justify-center">
          <div class="w-12 h-12 relative bg-emerald-500 p-4 rounded-full cursor-pointer" @click="changeReservationStatus(1)">
            <div class="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center" style="top: 25%">
              <i class="text-white text-lg fa-solid fa-thumbs-up"></i>  
            </div>
          </div>
        </div>
        <div class="w-1/2 flex justify-center">
          <div class="w-12 h-12 relative bg-red-500 p-4 rounded-full cursor-pointer" @click="changeReservationStatus(0)">
            <div class="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center" style="top: 25%">
              <i class="text-white text-lg fa-solid fa-thumbs-down"></i>  
            </div>
          </div>
        </div>
      </div>
    </td>
    <td>
      <ResActionDropDown
        :reservationnumber="reservationObj.reservationnumber"
        @update-reservation="updateList"
        @update-status="updateList"
        @update-gender="updateGender"
      />
    </td>
  </tr>
  <teleport to="#modal">
    <SuccesAlert :show="showAlert" @resetShow="showAlert = false"/>
    <Modal v-if="openModal" :title="modalTitle" :text="modalText" @closeModal="openModal = false" />
  </teleport>
</template>
<script setup>
import formattedCurrency from "@/formattedCurrency.js";
</script>
<script>
import ResActionDropDown from "@/components/Dropdowns/ResActionDropDown.vue";
import Modal from "@/components/Elements/Modal.vue";
import SuccesAlert from '@/components/Elements/SuccesAlert.vue';

import { ChangeReservationStatus } from '@/graphql/reservations/mutations.js'; 

export default {
  components: {
    ResActionDropDown,
    Modal,
    SuccesAlert
  },
  props: {
    reservationObj: {
      default: false,
    },
    totalRes: {
      default: 0,
      type: Number,
    },
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      amountToPay: 0,
      payedAmount: 0,
      gender: this.reservationObj?.genderOfTeam,
      openModal: false,
      modalTitle: '',
      modalText: '',
      showAlert: false
    };
  },
  mounted() {
    this.amountToPay =
      this.reservationObj?.numberofguests *
      this.reservationObj?.deposit_fee.amount;
    for (
      let index = 0;
      index < this.reservationObj?.reservationPayments?.length;
      index++
    ) {
      if (
        this.reservationObj?.reservationPayments[index].status === 1 &&
        this.reservationObj?.reservationPayments[index].type !== "tent"
      ) {
        this.payedAmount +=
          this.reservationObj?.reservationPayments[index].amount;
      }
    }
  },
  computed: {
    percentagePaid() {
      return Math.floor((this.payedAmount / this.amountToPay) * 100);
    },
    formattedCurrency(amount) {
      return formattedCurrency(amount);
    },
  },
  methods: {
    updateList() {
      this.showAlert = true;
      this.$emit('update-list');
    },
    updateGender(gender) {
      this.gender = gender;
    },
    backgroundColorOnPercentage() {
      if (
        this.payedAmount >=
        this.reservationObj?.remnant_fee.amount *
          this.reservationObj?.numberofguests +
          this.reservationObj?.deposit_fee.amount *
            this.reservationObj?.numberofguests
      ) {
        return "bg-emerald-500";
      } else if (
        this.payedAmount >=
        this.reservationObj?.deposit_fee.amount *
          this.reservationObj?.numberofguests
      ) {
        return "bg-orange-500";
      } else {
        return "bg-red-500";
      }
    },
    payerName(id) {
      return this.reservationObj?.team?.members.find(
        (element) => element.id === id
      ).name;
    },
    async changeReservationStatus(status) {
      try {
        const res = await this.$apollo.mutate({
          mutation: ChangeReservationStatus,
          variables: {
            reservationnumber: this.reservationObj.reservationnumber,
            status
          },
        });
        if (res?.data?.ChangeReservationStatus.status == 200) {
          this.$emit('update-list');
          this.dropdownPopoverShow = false;
        } else {
          this.modalTitle = 'Er ging iets mis.'
          this.modalText = res?.data?.ChangeReservationStatus.statusMsg;
          this.openModal = true;
        }
      } catch (e) {
        console.error(e);
      }
    }
  },
};
</script>


<style scoped>
thead,
tbody tr {
  table-layout: fixed; /* even columns width , fix width of table too*/
}
</style>