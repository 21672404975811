const mutations = {
  setCurrWeekendId(state, id) {
    state.currWeekendId = id;
  },
  setLoggedIn(state, status) {
    state.loggedIn = status;
  },
  setRole(state, role) {
    state.role = role;
  },
};

export default mutations;
