<template>
  <span>
    <tr
      ref="btnDropdownRef"
      class="table w-full cursor-pointer"
      v-on:click="toggleDropdown($event)"
    >
      <slot></slot>
    </tr>
    <div
      ref="popoverDropdownRef"
      class="
        bg-white
        text-base
        z-50
        float-left
        py-2
        list-none
        text-left
        rounded
        shadow-lg
        min-w-48
      "
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <div
        @click="RemoveMember()"
        class="
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
          cursor-pointer
        "
      >
        Verwijder uit team
      </div>
      <div
        @click="ActivateUser()"
        v-if="!userActive"
        class="
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
          cursor-pointer
        "
      >
        Activate user
      </div>
      <a
        :href="`mailto:${userEmail}`"
        class="
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
        "
      >
        mail - {{userEmail}}
      </a>
      <a
        :href="`https://wa.me/${addCountryCode(userPhone)}`"
        class="
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-blueGray-700
        "
      >
      <i class="fa-brands fa-whatsapp"></i> - {{addCountryCode(userPhone)}}
      </a>
      <div class="h-0 my-2 border border-solid border-blueGray-100" />
      <div
        @click="deleteUser()"
        class="
          text-sm
          py-2
          px-4
          font-normal
          block
          w-full
          whitespace-nowrap
          bg-transparent
          text-red-500
          cursor-pointer
        "
      >
        Verwijder account
      </div>
    </div>
  </span>
  <teleport to="#modal">
    <SuccesAlert :show="showAlert" @resetShow="showAlert = false"/>
    <Modal v-if="openModal" :title="modalTitle" :text="modalText" @closeModal="openModal = false" />
  </teleport>
</template>

<script>
import { createPopper } from "@popperjs/core";

import Modal from "@/components/Elements/Modal.vue";
import SuccesAlert from '@/components/Elements/SuccesAlert.vue';


import { removeMember, activateUser } from "@/graphql/team/mutations.js";
import { deleteUser } from "@/graphql/user/mutations.js";

export default {
  components: {
    Modal,
    SuccesAlert
  },
  props: {
    userId: {
      default: null,
    },
    userEmail: {
      type: String,
      default: null,
    },
    userPhone: {
      type: String,
      default: null,
    },
    userGuid: {
      type: String,
      default: null,
    },
    reservationnumber: {
      type: String,
      default: null,
    },
    teamID: {
      type: Number,
      default: null,
    },
    userActive: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dropdownPopoverShow: false,
      openModal: false,
      modalTitle: '',
      modalText: '',
      showAlert: false
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      event.stopPropagation();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-end",
        });
      }
    },
    async RemoveMember() {
      try {
        const res = await this.$apollo.mutate({
          mutation: removeMember,
          variables: {
            guid: this.userGuid,
            reservationnumber: this.reservationnumber,
            teamID: this.teamID,
          },
        });
        if (res?.data?.removeMember.status == 200) {
          this.$emit("update-reservation");
          this.showAlert = true;
          this.dropdownPopoverShow = false;
        } else {
          this.modalTitle = 'Er ging iets mis.'
          this.modalText = res?.data?.removeMember.statusMsg;
          this.openModal = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async ActivateUser() {
      try {
        const res = await this.$apollo.mutate({
          mutation: activateUser,
          variables: {
            guid: this.userGuid,
          },
        });
        if (res?.data?.activateUser.status == 200) {
          this.$emit("update-reservation");
          this.showAlert = true;
          this.dropdownPopoverShow = false;
        } else {
          this.modalTitle = 'Er ging iets mis.'
          this.modalText = res?.data?.activateUser.statusMsg;
          this.openModal = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async deleteUser() {
      try {
        const res = await this.$apollo.mutate({
          mutation: deleteUser,
          variables: {
            userID: this.userId,
          },
        });
        if (res?.data?.deleteUser.status == 200) {
          this.$emit("update-reservation");
          this.showAlert = true;
          this.dropdownPopoverShow = false;
        } else {
          this.modalTitle = 'Er ging iets mis.'
          this.modalText = res?.data?.deleteUser.statusMsg;
          this.openModal = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
    addCountryCode(telNumber) {
      // @TODO: temp code. Needs a way better solution.
      if(telNumber.startsWith("0032")) {
        return telNumber.replace("0032", "32");
      } else if (telNumber.startsWith("04")) {
        return telNumber.replace("04", "324");
      } else if (telNumber.startsWith("06")) {
        return telNumber.replace("06", "316");
      } else if (telNumber.startsWith("6")) {
        return telNumber.replace("6", "316");
      }
    }
  },
};
</script>
<style scoped>
thead,
tbody tr {
  table-layout: fixed; /* even columns width , fix width of table too*/
}
</style>