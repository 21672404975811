<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <card-line-chart
          :title="'Totaal aantal gasten'"
          :loading="$apollo.queries.eventCompairStats.loading"
          :prevWeekend="prevNOGAmount"
          :prevYear="
            eventCompairStats?.prevWeekendDate
              ? new Date(eventCompairStats?.prevWeekendDate).getFullYear()
              : 'no '
          "
          :currWeekend="currNOGAmount"
          :currYear="new Date(eventCompairStats?.currWeekendDate).getFullYear()"
          :chartId="'NOG'"
        />
      </div>
      <div class="w-full xl:w-4/12 px-4">
        <card-bar-chart
          :title="`${
            eventCompairStats?.prevWeekendDate
              ? new Date(eventCompairStats?.prevWeekendDate).getFullYear()
              : 'no '
          } event`"
          :subTitle="'Man / vrouw verhouding'"
          :loading="$apollo.queries.eventCompairStats.loading"
          :first="prevWomenNogAmount"
          :firstLable="'Women'"
          :second="prevManNogAmount"
          :secondLable="'Man'"
          :third="prevMixNogAmount"
          :thirdLable="'Mix'"
          :chartId="'ratioPrev'"
        />
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <card-line-chart
          :title="'Reserveringen'"
          :loading="$apollo.queries.eventCompairStats.loading"
          :prevWeekend="prevReservationAmount"
          :prevYear="
            eventCompairStats?.prevWeekendDate
              ? new Date(eventCompairStats?.prevWeekendDate).getFullYear()
              : 'no '
          "
          :currWeekend="currReservationAmount"
          :currYear="new Date(eventCompairStats?.currWeekendDate).getFullYear()"
          :chart-id="'reservations'"
        />
      </div>
      <div class="w-full xl:w-4/12 px-4">
        <card-bar-chart
          :title="`${new Date(
            eventCompairStats?.currWeekendDate
          ).getFullYear()} event`"
          :subTitle="'Man / vrouw verhouding'"
          :loading="$apollo.queries.eventCompairStats.loading"
          :first="currWomenNogAmount"
          :firstLable="'Women'"
          :second="currManNogAmount"
          :secondLable="'Man'"
          :third="currMixNogAmount"
          :thirdLable="'Mix'"
          :chartId="'ratioCurrent'"
        />
      </div>
    </div>
    <div class="px-4 py-3">
      <span class="mr-2">Week:</span>
      <select @change="changeWeek" name="weekNr" id="weekNr">
        <option
          v-for="(weekNrObj, index) in weekNrs"
          v-show="
            (weekNrObj.year < moment().year()) || (weekNrObj.year <= moment().year() && weekNrObj.weekNr <= moment().isoWeek())
          "
          :key="`week-${index}`"
          :value="`${weekNrObj.weekNr}-${weekNrObj.year}`"
        >
          {{ weekNrObj.weekNr }} - {{ weekNrObj.year }}
        </option>
      </select>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <card-line-chart
          :title="'Wekelijkse reserveringen'"
          :loading="$apollo.queries.eventWeeklyStats.loading"
          :currWeekend="weeklyReservationAmount"
          :currYear="`week ${weekNrToShow}`"
          :chart-id="'weeklyReservations'"
          :labelType="'weekly'"
          
          :weekNrToShow="weekNrToShow"
          :weekNrYearToShow="weekNrYearToShow?.toString()"
        />
      </div>

      <div class="w-full xl:w-4/12 px-4">
        <card-bar-chart
          :title="`week ${weekNrToShow}`"
          :subTitle="'Weekelijkse Man / vrouw verhouding'"
          :loading="$apollo.queries.eventWeeklyStats.loading"
          :first="weeklyWomenNogAmount"
          :firstLable="'Women'"
          :second="weeklyManNogAmount"
          :secondLable="'Man'"
          :third="weeklyMixNogAmount"
          :thirdLable="'Mix'"
          :chartId="'weeklyCurrent'"
          :labelType="'weekly'"
          
          :weekNrToShow="weekNrToShow"
          :weekNrYearToShow="weekNrYearToShow?.toString()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

import CardLineChart from "@/components/Cards/CardLineChart.vue";
import CardBarChart from "@/components/Cards/CardBarChart.vue";

import {
  getEventCompairStats,
  getEventReservationWindow,
  getEventWeeklyStats,
} from "@/graphql/events/query.js";

export default {
  name: "dashboard-page",
  components: {
    CardLineChart,
    CardBarChart,
  },
  mounted() {
    this.$apollo.queries.eventCompairStats.refetch();
  },
  data() {
    return {
      weekNrToShow: null,
      weekNrYearToShow: null,

      prevWeekend: {},
      currWeekend: {},
      prevReservationAmount: [],
      currReservationAmount: [],
      prevNOGAmount: [],
      currNOGAmount: [],
      prevManNogAmount: [],
      prevWomenNogAmount: [],
      prevMixNogAmount: [],
      currManNogAmount: [],
      currWomenNogAmount: [],
      currMixNogAmount: [],

      weekNrs: [],
      weeklyWeekend: {},

      weeklyNOGAmount: [],
      weeklyWomenNogAmount: [],
      weeklyManNogAmount: [],
      weeklyMixNogAmount: [],
      weeklyReservationAmount: [],

      startDate: null,
    };
  },
  apollo: {
    // getReservationWindow = the time beteen prev event and current event (selected in weekend select above).
    eventReservationWindow() {
      return {
        query: getEventReservationWindow,
        variables: () => {
          return {
            weekendID: parseInt(this.$store.getters.currWeekendId),
          };
        },
        skip: () =>
          !this.$store.getters.currWeekendId,
        result({ data }) {
          this.weekNrs = [];
          this.startDate = data?.eventReservationWindow?.startDate;
          const startWeekNr = moment(data?.eventReservationWindow?.startDate)
            .isoWeek();

          const endWeekNr = moment(
            data?.eventReservationWindow?.endDate
          ).week();

          const startYear = moment(
            data?.eventReservationWindow?.startDate
          ).year();

          const endYear = moment(data?.eventReservationWindow?.endDate).year();
          const isoAmontOfWeeks = moment(startYear).weeksInYear();

          if (endYear > startYear) {
            for (let i = startWeekNr; i <= isoAmontOfWeeks; i++) {
              this.weekNrs.push({ weekNr: i, year: startYear });
            }

            for (let i = 1; i <= endWeekNr; i++) {
              this.weekNrs.push({ weekNr: i, year: endYear });
            }
          } else {
            for (let i = startWeekNr; i <= endWeekNr; i++) {
              this.weekNrs.push({ weekNr: i, year: startYear });
            }
          }

          this.weekNrToShow = this.weekNrs[0].weekNr;
          this.weekNrYearToShow = this.weekNrs[0].year;
        },
      };
    },
    eventWeeklyStats() {
      return {
        query: getEventWeeklyStats,
        skip() {
          return !this.weekNrToShow;
        },
        variables: () => {
          return {
            weekendID: parseInt(this.$store.getters.currWeekendId),
            year: this.weekNrYearToShow.toString(),
            weekNr: this.weekNrToShow,
          };
        },
        result({ data }) {
          const reservations = data?.eventWeeklyStats?.reservations;
          this.weeklyWeekend = {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
          };
          this.weeklyNOGAmount = [];
          this.weeklyWomenNogAmount = [];
          this.weeklyManNogAmount = [];
          this.weeklyMixNogAmount = [];
          this.weeklyReservationAmount = [];

          for (let i = 0; i < reservations.length; i++) {
            const reservation = reservations[i];
            const reserVationCreateDay = moment(
              reservation?.createdDateTime
            ).day();

            if (reserVationCreateDay == 0) {
              // because i want monday to be start of the week
              this.weeklyWeekend[7].push(reservation);
            } else {
              this.weeklyWeekend[reserVationCreateDay].push(reservation);
            }
          }

          for (const week in this.weeklyWeekend) {
            let NOG = 0;
            let manNOG = 0;
            let mixNOG = 0;
            let womenNOG = 0;

            NOG += this.weeklyWeekend[week].reduce(
              (a, b) => a + b.numberofguests,
              0
            );

            womenNOG += this.weeklyWeekend[week]
              .filter(({ genderOfTeam }) => genderOfTeam == "Vrouwen")
              .reduce((a, b) => a + b.numberofguests, 0);

            manNOG += this.weeklyWeekend[week]
              .filter(({ genderOfTeam }) => genderOfTeam == "Mannen")
              .reduce((a, b) => a + b.numberofguests, 0);

            mixNOG += this.weeklyWeekend[week]
              .filter(({ genderOfTeam }) => genderOfTeam == "Mix")
              .reduce((a, b) => a + b.numberofguests, 0);

            this.weeklyNOGAmount.push(NOG);
            this.weeklyWomenNogAmount.push(womenNOG);
            this.weeklyManNogAmount.push(manNOG);
            this.weeklyMixNogAmount.push(mixNOG);

            this.weeklyReservationAmount.push(this.weeklyWeekend[week].length);
          }
        },
      };
    },
    eventCompairStats() {
      return {
        query: getEventCompairStats,
        variables: () => {
          return {
            weekendID: parseInt(this.$store.getters.currWeekendId),
          };
        },
        skip: () =>
          !this.$store.getters.currWeekendId,
        result({ data }) {
          this.prevWeekend = {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
          };
          this.currWeekend = {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
          };
          
          this.prevNOGAmount = [];
          this.prevWomenNogAmount = [];
          this.prevManNogAmount = [];
          this.prevMixNogAmount = [];
          this.prevReservationAmount = [];
          this.currNOGAmount = [];
          this.currWomenNogAmount = [];
          this.currManNogAmount = [];
          this.currMixNogAmount = [];
          this.currReservationAmount = [];

          for (
            let i = 0;
            i < data?.eventCompairStats.reservations.length;
            i++
          ) {
            const reservation = data?.eventCompairStats.reservations[i];

            const d = new Date(reservation?.createdDateTime);
            let monthNr = d.getMonth();

            if (
              reservation?.weekendID == data?.eventCompairStats.prevWeekendId
            ) {
              this.prevWeekend[monthNr].push(reservation);
            } else {
              this.currWeekend[monthNr].push(reservation);
            }
          }

          for (const month in this.prevWeekend) {
            let NOG = 0;
            let manNOG = 0;
            let mixNOG = 0;
            let womenNOG = 0;

            NOG += this.prevWeekend[month].reduce(
              (a, b) => a + b.numberofguests,
              0
            );

            womenNOG += this.prevWeekend[month]
              .filter(({ genderOfTeam }) => genderOfTeam == "Vrouwen")
              .reduce((a, b) => a + b.numberofguests, 0);

            manNOG += this.prevWeekend[month]
              .filter(({ genderOfTeam }) => genderOfTeam == "Mannen")
              .reduce((a, b) => a + b.numberofguests, 0);

            mixNOG += this.prevWeekend[month]
              .filter(({ genderOfTeam }) => genderOfTeam == "Mix")
              .reduce((a, b) => a + b.numberofguests, 0);

            this.prevNOGAmount.push(NOG);
            this.prevWomenNogAmount.push(womenNOG);
            this.prevManNogAmount.push(manNOG);
            this.prevMixNogAmount.push(mixNOG);

            this.prevReservationAmount.push(this.prevWeekend[month].length);
          }

          for (const month in this.currWeekend) {
            let NOG = 0;
            let manNOG = 0;
            let womenNOG = 0;
            let mixNOG = 0;

            NOG += this.currWeekend[month].reduce(
              (a, b) => a + b.numberofguests,
              0
            );

            womenNOG += this.currWeekend[month]
              .filter(({ genderOfTeam }) => genderOfTeam == "Vrouwen")
              .reduce((a, b) => a + b.numberofguests, 0);

            manNOG += this.currWeekend[month]
              .filter(({ genderOfTeam }) => genderOfTeam == "Mannen")
              .reduce((a, b) => a + b.numberofguests, 0);

            mixNOG += this.currWeekend[month]
              .filter(({ genderOfTeam }) => genderOfTeam == "Mix")
              .reduce((a, b) => a + b.numberofguests, 0);

            this.currNOGAmount.push(NOG);
            this.currWomenNogAmount.push(womenNOG);
            this.currManNogAmount.push(manNOG);
            this.currMixNogAmount.push(mixNOG);

            this.currReservationAmount.push(this.currWeekend[month].length);
          }
        },
      };
    },
  },
  methods: {
    moment: function () {
      return moment();
    },
    changeWeek(e) {
      const date = e.target.value.split("-");
      this.weekNrToShow = parseInt(date[0]);
      this.weekNrYearToShow = date[1];
    },
  },
};
</script>
