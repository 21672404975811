import { onError } from '@apollo/client/link/error';

// Log any GraphQL errors or network error that occurred
export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

    const { message } = graphQLErrors[0];

    if (message === 'User is not authenticated') {
      window.dispatchEvent(
        new CustomEvent('auth', {
          detail: {
            isAuthenticated: false,
          },
        })
      );
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});
